import { Card } from 'react-bootstrap';
import '../../pages/NewWorkOrder.scss';

const LaborCodes = () => (
  <Card className="full-button">
    <h6>Labor Codes</h6>
  </Card>
);

export default LaborCodes;
