import { Card, Form } from 'react-bootstrap';
import { Input, Button } from '@flogistix/flo-ui';
import { useState } from 'react';
import GasEngineReadings from './GasEngineReadings';
import './GasEngineChecklist.scss';
import RecordEngineCompression from './RecordEngineCompression';

const GasEngineChecklist = () => {
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(!show);

  return (
    <Card className={show ? 'ge-checklist-button-active' : 'ge-checklist-button'}>
      <Card className="ge-card" onClick={handleOpen}>
        <h6>Gas Engine Checklist</h6>
      </Card>
      {show ? (
        <div>
          <div className="ge-dropdown-div">
            <div className="ge-checkbox-div">
              <Form.Check className="ge-checkbox" label="Check/Clean Mag Pick-ups" />
              <Form.Check className="ge-checkbox" label="Change Engine Oil and Filters" />
              <Form.Check className="ge-checkbox" label="Check Air Cleaners" />
              <Form.Check className="ge-checkbox" label="Change Spark Plugs" />
            </div>
            <div className="ge-checkbox-div">
              <Form.Check className="ge-checkbox" label="Check and Record Engine Timing" />
              <Form.Check className="ge-checkbox" label="Check Gov. Response" />
              <Form.Check className="ge-checkbox" label="Check Battery" />
              <Form.Check className="ge-checkbox" label="Check Wires" />
            </div>
            <div className="ge-checkbox-div">
              <Form.Check className="ge-checkbox" label="Check Water Pump Belts for Tension" />
              <Form.Check className="ge-checkbox" label="Check Distributor Cap and Rotor" />
              <Form.Check className="ge-checkbox" label="Change Engine Oil" />
              <Form.Check className="ge-checkbox" label="Adjust Valves" />
            </div>
          </div>
          <GasEngineReadings />
          <RecordEngineCompression />
        </div>
      ) : <div /> }
    </Card>
  );
};
export default GasEngineChecklist;
