import {
  useContext,
  useState,
  ChangeEvent
} from 'react';
import { Form } from 'react-bootstrap';
import { Dropdown, Input } from '@flogistix/flo-ui';
import { JSAContext } from '../../context/JSAContext';
import { GlobalContext } from '../../context/GlobalContext';
import './JSAEmergencyEvacuation.scss';

const JSAEmergencyEvacuation = () => {
  const { jsa } = useContext(JSAContext);
  const { token } = useContext(GlobalContext);

  const weatherConditions = [
    'Sunny',
    'Cloudy',
    'Rainy',
    'Snowy',
    'Windy',
    'Stormy',
    'Foggy',
    'Hazy',
    'Thunderstorm',
    'Drizzle',
    'Blizzard',
    'Hail',
    'Overcast'
  ];
  const weatherConditionsSearchItems = weatherConditions.map((term) => ({ label: term, value: term }));
  const [weatherConditionTerm, setWeatherConditionTerm] = useState<string>('');
  const [weatherCondition, setWeatherCondition] = useState(
    jsa?.emergency_evacuation_instructions?.weather_condition as string
  );
  const handleWeatherCondition = (updatedWeatherCondition: string) => {
    setWeatherCondition(updatedWeatherCondition);
    jsa?.handleWeatherCondition(token, updatedWeatherCondition);
  };

  const windDirections = [
    'North',
    'North-Northeast',
    'Northeast',
    'East-Northeast',
    'East',
    'East-Southeast',
    'Southeast',
    'South-Southeast',
    'South',
    'South-Southwest',
    'Southwest',
    'West-Southwest',
    'West',
    'West-Northwest',
    'Northwest',
    'North-Northwest'
  ];

  const windFromSearchItems = windDirections.map((term) => ({ label: term, value: term }));
  const [windFromTerm, setWindFromTerm] = useState<string>('');
  const [windFrom, setWindFrom] = useState(jsa?.emergency_evacuation_instructions?.wind?.blowing_from as string);
  const handleWindFrom = (updatedWindFrom: string) => {
    setWindFrom(updatedWindFrom);
    jsa?.handleWindBlowingFrom(token, updatedWindFrom);
  };

  const windToSearchItems = windDirections.map((term) => ({ label: term, value: term }));
  const [windToTerm, setWindToTerm] = useState<string>('');
  const [windTo, setWindTo] = useState(jsa?.emergency_evacuation_instructions?.wind?.blowing_to as string);
  const handleWindTo = (updatedWindTo: string) => {
    setWindTo(updatedWindTo);
    jsa?.handleWindBlowingTo(token, updatedWindTo);
  };

  const [windSpeed, setWindSpeed] = useState(jsa?.emergency_evacuation_instructions?.wind?.wind_speed ?? 0);
  const handleWindSpeed = (updatedWindSpeed: number) => {
    jsa?.handleWindSpeed(token, updatedWindSpeed);
  };

  const [primaryMusterPoint, setPrimaryMusterPoint] = useState(
    jsa?.emergency_evacuation_instructions?.primary_muster_point as string
  );
  const handlePrimaryMusterPoint = () => {
    jsa?.handlePrimaryMusterPoint(token, primaryMusterPoint as string);
  };
  const changePrimaryMusterPoint = (input: ChangeEvent<HTMLTextAreaElement>) => {
    setPrimaryMusterPoint(input.target.value);
  };

  const [secondaryMusterPoint, setSecondaryMusterPoint] = useState(
    jsa?.emergency_evacuation_instructions?.secondary_muster_point as string
  );
  const handleSecondaryMusterPoint = () => {
    jsa?.handleSecondaryMusterPoint(token, secondaryMusterPoint);
  };
  const changeSecondaryMusterPoint = (input: ChangeEvent<HTMLTextAreaElement>) => {
    setSecondaryMusterPoint(input.target.value);
  };

  return (
    <div>
      <div className="sub-section-header">
        <h6 className="title">Emergency Evacuation Plan</h6>
        <h6 className="sub-title">Take 5 minutes to look for general hazards that could be impactful.</h6>
      </div>
      <div>
        <label className="label">Weather Conditions</label>
        <br />
        <Dropdown
          className="weather-conditions-dropdown"
          placeholder="Select"
          options={
            weatherConditionsSearchItems.filter((item) => item.label.toLowerCase().includes(
              weatherConditionTerm.toLowerCase()
            ))
          }
          onSelect={(option) => {
            setWeatherCondition(option.label);
            handleWeatherCondition(option.label);
          }}
          value={weatherCondition}
          searchValue={weatherConditionTerm}
          onSearchChange={setWeatherConditionTerm}
        />
      </div>
      <div className="container">
        <div>
          <label className="label">Wind is blowing from...</label>
          <br />
          <Dropdown
            className="wind"
            placeholder="Select"
            options={
              windFromSearchItems.filter((item) => item.label.toLowerCase().includes(
                windFromTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setWindFrom(option.label);
              handleWindFrom(option.label);
            }}
            value={windFrom}
            searchValue={windFromTerm}
            onSearchChange={setWindFromTerm}
          />
        </div>
        <div>
          <label className="label">to...</label>
          <br />
          <Dropdown
            className="wind"
            placeholder="Select"
            options={
              windToSearchItems.filter((item) => item.label.toLowerCase().includes(
                windToTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setWindTo(option.label);
              handleWindTo(option.label);
            }}
            value={windTo}
            searchValue={windToTerm}
            onSearchChange={setWindToTerm}
          />
        </div>
        <div>
          <label className="label">at...</label>
          <br />
          <Input
            className="wind-speed"
            value={windSpeed}
            onChange={(event) => setWindSpeed(parseInt(event.target.value, 10))}
            placeholder="Select"
            type="number"
            suffix="mph"
            onBlur={() => handleWindSpeed(windSpeed)}
          />
        </div>
      </div>
      <div className="container">
        <label className="sub-title">Muster Points</label>
        <div className="flex-break" />
        <div>
          <label className="label">Primary</label>
          <br />
          <Form.Text
            className="muster-text-box"
            as="textarea"
            placeholder="Enter a Description..."
            style={{ height: '154px', width: '320px' }}
            value={primaryMusterPoint}
            onBlur={handlePrimaryMusterPoint}
            onChange={changePrimaryMusterPoint}
          />
        </div>
        <div>
          <label className="label">Secondary</label>
          <br />
          <Form.Text
            className="muster-text-box"
            as="textarea"
            placeholder="Enter a Description..."
            style={{ height: '154px', width: '320px' }}
            value={secondaryMusterPoint}
            onBlur={handleSecondaryMusterPoint}
            onChange={changeSecondaryMusterPoint}
          />
        </div>
      </div>
    </div>
  );
};

export default JSAEmergencyEvacuation;
