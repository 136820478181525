import { Card, Form } from 'react-bootstrap';
import { Input, Dropdown } from '@flogistix/flo-ui';
import { useState } from 'react';
import './EmissionsTesting.scss';

const EmissionsTesting = () => {
  const searchItems = [{
    label: 'Head Change',
    value: 'Head Change'
  }, {
    label: 'Catalyst Change',
    value: 'Catalyst Change'
  }, {
    label: 'Exhaust Gaskets',
    value: 'Exhaust Gaskets'
  }];
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const handleOpen = () => setShow(!show);

  return (
    <Card className={show ? 'emission-button-active' : 'emission-button'}>
      <Card className="emissions-card" onClick={handleOpen}>
        <h6>Emissions Testing</h6>
      </Card>
      { show ? (
        <div>
          <div className="emissions-dropdown-div">
            <div>
              <label className="emissions-label">02 Percent (%)</label>
              <br />
              <Input className="emissions-input" type="number" fixedWidth="90%" />
            </div>
            <div>
              <label className="emissions-label">NOx (PPM)</label>
              <br />
              <Input className="emissions-input" type="number" fixedWidth="90%" />
            </div>
          </div>
          <div>
            <label className="emissions-label">Reason for test or check</label>
            <Dropdown
              className="emissions-dropdown"
              placeholder="Select Reason"
              options={searchItems.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()))}
              onSelect={(option) => setValue(option.label)}
              value={value}
              searchValue={searchTerm}
              onSearchChange={setSearchTerm}
            />
            <Form.Check className="emissions-checkbox" label="02 Sensor Changed" />
          </div>
          <div>
            <h6 className="emissions-label">Emissions Test</h6>
            <div className="emissions-div">
              <div>
                <label className="emissions-label">CO (PPM)</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
              <div>
                <label className="emissions-label">Catalyst Temp Pre-Cat (f)</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
            </div>
            <div className="emissions-div">
              <div>
                <label className="emissions-label">Engine Serial Number</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
              <div>
                <label className="emissions-label">AFR mV</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
            </div>
            <div className="emissions-div">
              <div>
                <label className="emissions-label">Catalyst Temp Post-Cat (f)</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
              <div>
                <label className="emissions-label">Permit Number</label>
                <br />
                <Input className="emissions-input" type="number" fixedWidth="90%" />
              </div>
            </div>
          </div>
        </div>
      ) : <div /> }
    </Card>
  );
};

export default EmissionsTesting;
