import {
  useState, createContext, ReactNode, useMemo
} from 'react';
import { WorkOrder } from '../classes/workorder';

interface WorkOrderContextShape {
  workOrder: WorkOrder
}

const defaultWorkOrderContext: WorkOrderContextShape = {
  workOrder: new WorkOrder()
};

export const WorkOrderContext = createContext<WorkOrderContextShape>(defaultWorkOrderContext);

export const WorkOrderProvider = ({ children }: { children: ReactNode }) => {
  const [workOrder, setWorkOrder] = useState<WorkOrder>(new WorkOrder());
  const providerValue = useMemo(
    () => ({
      workOrder,
      setWorkOrder
    }),
    [
      workOrder,
      setWorkOrder
    ]
  );

  return (
    <WorkOrderContext.Provider value={providerValue}>
      {children}
    </WorkOrderContext.Provider>
  );
};
