import { IndexableType } from 'dexie';
import {
  db,
  JsonType,
  Transaction,
  WorkOrder,
  JSA,
  Unit
} from './db';
import { JSA as JSAClass } from '../classes/jsa';
import { WorkOrder as WorkOrderClass } from '../classes/workorder';
import sendMessageToSW from '../shared/utils';

const activeStatuses = ['queued', 'processing', 'retrying'];

export const addOrUpdateWorkOrder = async (
  id: JsonType,
  workOrder: JsonType
): Promise<WorkOrder | IndexableType> => {
  const existingWorkOrder = await db.workOrders.get(id);
  if (existingWorkOrder) {
    return db.workOrders.update(existingWorkOrder, workOrder);
  }
  return db.workOrders.add(workOrder as WorkOrder) as Promise<number>;
};

export const addOrUpdateJSA = async (
  id: JsonType,
  jsa: JsonType
): Promise<JSA | IndexableType> => {
  const existingJSA = await db.jsas.get(id);
  if (existingJSA) {
    return db.jsas.update(existingJSA, jsa);
  }
  return db.jsas.add(jsa as JSA) as Promise<number>;
};

export const addOrUpdateWorkOrderVersion = async (id: JsonType): Promise<WorkOrder> => {
  const workOrder = await db.workOrders.get(id) as WorkOrder;
  if (!workOrder) {
    throw new Error('Work Order not found');
  }
  if (workOrder.version !== undefined) {
    workOrder.version += 1;
  } else {
    workOrder.version = 0;
  }
  await db.workOrders.update(id, {
    version: workOrder.version,
    updatedAt: new Date()
  });
  const updatedWorkOrder = await db.workOrders.get(id);
  return updatedWorkOrder as WorkOrder;
};

export const addOrUpdateJSAVersion = async (id: JsonType): Promise<JSA> => {
  const jsa = await db.jsas.get(id) as JSA;
  if (!jsa) {
    throw new Error('JSA not found');
  }
  if (jsa.version !== undefined) {
    jsa.version += 1;
  } else {
    jsa.version = 0;
  }
  await db.jsas.update(id, {
    version: jsa.version,
    updatedAt: new Date()
  });
  const updatedJSA = await db.jsas.get(id);
  return updatedJSA as JSA;
};

export const addOrUpdateUnits = async (unit: Unit) => {
  const currentUnit = await db.units.get(unit.id);
  if (!currentUnit) await db.units.add(unit);
  else await db.units.update(unit.id, unit);
};

export const getUnitIds = async () => {
  const unit_ids = await db.units.toArray().then((value) => value.map((unit) => unit.skidId));
  return unit_ids;
};

export const createTransaction = async ({
  queueId,
  request,
  status = 'queued',
  attempts = 0,
  response,
  type
}: Transaction, object: JSAClass | WorkOrderClass): Promise<Transaction> => {
  const id = await db.transactions.add({
    queueId,
    request,
    status,
    attempts,
    createdAt: new Date(),
    response,
    type
  });
  const createdTransaction = await db.transactions.get(id);
  if (object) {
    if (type === 'workOrder') {
      const workOrderIndexDB = await db.workOrders.get(queueId as unknown as JsonType);
      const newWorkOrder = object;
      newWorkOrder.version = workOrderIndexDB?.version;
      await addOrUpdateWorkOrder(queueId as unknown as JsonType, newWorkOrder as unknown as JsonType);
    } else if (type === 'jsa') {
      const jsaIndexDB = await db.jsas.get(queueId as unknown as JsonType);
      const newJSA = object;
      newJSA.version = jsaIndexDB?.version;
      await addOrUpdateJSA(queueId as unknown as JsonType, newJSA as unknown as JsonType);
    }
  }
  sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
  return createdTransaction!;
};

const ageInMinutes = (now: Date, incomingDate: Date) => (
  (now.getTime() - incomingDate.getTime()) / (1000 * 60)
);

export const getVersionByWorkOrderId = async (id: JsonType): Promise<WorkOrder['version']> => {
  const workOrder = await db.workOrders.get(id);
  if (!workOrder) {
    throw new Error('No Work Order with this Id');
  }
  const currentVersion = workOrder.version;
  return currentVersion;
};

export const getVersionByJSAId = async (id: JsonType): Promise<JSA['version']> => {
  const jsa = await db.jsas.get(id);
  if (!jsa) {
    throw new Error('No JSA with this Id');
  }
  const currentVersion = jsa.version;
  return currentVersion;
};

export const incrementTransactionAttempts = async (id: number): Promise<Transaction> => {
  const transaction = await db.transactions.get(id);
  if (!transaction) {
    throw new Error('Transaction not found');
  }
  await db.transactions.update(id, {
    attempts: (transaction.attempts ?? 0) + 1,
    updatedAt: new Date()
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const deleteOldFailedTransactions = async (): Promise<void> => {
  const now = new Date();
  const sevenDaysInMinutes = 7 * 24 * 60;
  const failedTransactionsToDelete = await db.transactions.where('status')
    .equals('failed').and((transaction) => (
      ageInMinutes(now, transaction.updatedAt ?? now) > sevenDaysInMinutes
    )).toArray();
  await db.transactions.bulkDelete(
    failedTransactionsToDelete.map((transaction) => transaction.id!)
  );
};

export const moveOldRetryingTransactionsToFailed = async (): Promise<void> => {
  const now = new Date();
  const retryingTransactions = await db.transactions.where('status').equals('retrying').toArray();
  const transactionsToFail = retryingTransactions.filter((transaction) => (
    ageInMinutes(now, transaction.processingStartedAt ?? now) > (12 * 60)
  )).map((transaction) => ({
    key: transaction.id!,
    changes: {
      status: 'failed' as keyof Transaction['status'],
      updatedAt: now
    }
  }));
  await db.transactions.bulkUpdate(transactionsToFail);
};

export const updateTransactionStatus = async (
  id: Transaction['id'],
  status: Transaction['status']
): Promise<Transaction> => {
  const now = new Date();
  const existingTransaction = await db.transactions.get(id);
  await db.transactions.update(id, {
    status,
    updatedAt: now,
    ...(
      status === 'processing'
      && !existingTransaction?.processingStartedAt
      && { processingStartedAt: now }
    ),
    ...(
      status === 'completed'
      && !existingTransaction?.completedAt
      && { completedAt: now }
    )
  });
  const updatedTransaction = await db.transactions.get(id);
  return updatedTransaction!;
};

export const getAllTransactions = async (): Promise<Transaction[]> => (
  db.transactions.toArray()
);

export const getAllFailedTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('failed').toArray()
);

export const getAllRetryingTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('retrying').toArray()
);

export const getAllCompletedTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('completed').toArray()
);

export const getAllProcessingTransactions = async (): Promise<Transaction[]> => (
  db.transactions.where('status').equals('processing').toArray()
);

export const getTransactionsQueue = async (): Promise<Transaction[]> => {
  const oneDayAgo = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  return db.transactions
    .where('status').anyOf(activeStatuses)
    .and((transaction) => (
      transaction.processingStartedAt! > oneDayAgo || !transaction.processingStartedAt))
    .toArray();
};
