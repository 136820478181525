import {
  useState, createContext, ReactNode, useMemo
} from 'react';
import { JSA } from '../classes/jsa';

interface JSAContextShape {
  jsa: JSA | null
}

const defaultJSAContext: JSAContextShape = {
  jsa: new JSA()
};

export const JSAContext = createContext<JSAContextShape>(defaultJSAContext);

export const JSAProvider = ({ children }: { children: ReactNode }) => {
  const [jsa, setJSA] = useState<JSA>(new JSA());

  const providerValue = useMemo(
    () => ({
      jsa,
      setJSA
    }),
    [
      jsa,
      setJSA
    ]
  );

  return (
    <JSAContext.Provider value={providerValue}>
      {children}
    </JSAContext.Provider>
  );
};
