import { useEffect, useState, useContext } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from './components/Header/Header';
import AppRouter from './AppRoutes';
import Loading from './FullScreenLoader';
import PathNames from './shared/paths';
import getUnits from './services/getUnits';
import { GlobalContext } from './context/GlobalContext';
import { addOrUpdateUnits } from './dexie/operations';
import './App.scss';
import { Unit } from './dexie/db';

const App = () => {
  const {
    isLoading,
    isAuthenticated,
    loginWithRedirect
  } = useAuth0();

  const [isDefaultPath, setDefaultPath] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useContext(GlobalContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (isAuthenticated) {
      if (!PathNames.find((path) => path === pathname)) {
        navigate('/home');
      }
      setDefaultPath(true);
    }
  }, [isAuthenticated, isLoading, loginWithRedirect]);

  useEffect(() => {
    if (!isDefaultPath) {
      return setLoading(false);
    }
    return setLoading(true);
  }, [isDefaultPath]);

  return loading ? (
    <div className="loading-in">
      <Header />
      <AppRouter />
    </div>
  ) : <Loading />;
};

export default App;
