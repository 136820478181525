import { Card, CloseButton } from 'react-bootstrap';
import { Button, SearchBar } from '@flogistix/flo-ui';
import { BsDot } from 'react-icons/bs';
import { useState, useContext, useEffect } from 'react';
import { WorkOrderContext } from '../../context/WorkOrderContext';
import './PartsUsed.scss';

const PartsUsed = () => {
  const { workOrder } = useContext(WorkOrderContext);
  const [show, setShow] = useState(false);
  const [value, setValue] = useState('');
  const [parts, setParts] = useState(workOrder.part_ids as string[]);

  const handleShow = () => setShow(!show);

  const addPart = (newPart: string) => {
    if (newPart !== '') {
      setParts([...parts as string[], newPart]);
    }
  };

  const deletePart = (deletedPart: string) => {
    const index = parts.findIndex((part) => part === deletedPart);
    const partsCopy = [...parts];
    partsCopy.splice(index, 1);
    setParts(partsCopy);
  };

  useEffect(() => {
    workOrder.part_ids = parts;
  }, [parts]);

  return (
    <Card className={show ? 'parts-button-active' : 'parts-button'}>
      <Card className="parts-card" onClick={handleShow}>
        <h6>Parts Used</h6>
      </Card>
      {show ? (
        <div className="parts-dropdown-div">
          <h6 className="parts-header">Enter Part Id:</h6>
          <div className="search-div">
            <SearchBar
              dropShadow
              placeholder="Part Id"
              variation="search"
              size="small"
              value={value}
              onChange={(e) => setValue(e.target.value)}

            />
            <Button fixedWidth="50px" className="add-parts-button" onClick={() => addPart(value)}>Add Part</Button>
          </div>
          {parts.length > 0 ? (
            <div className="parts-div">
              <h6 className="parts-header">Parts Used</h6>
              {parts?.map((part) => (
                <label className="parts-label">
                  <BsDot className="label-icon" />
                  {part}
                  <CloseButton className="parts-close" onClick={() => deletePart(part)} />
                </label>
              ))}
            </div>
          ) : <div /> }
        </div>
      ) : <div />}
    </Card>
  );
};

export default PartsUsed;
