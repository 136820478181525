import React, { useContext, useState } from 'react';
import { DualInput, Dropdown } from '@flogistix/flo-ui';
import { GlobalContext } from '../../context/GlobalContext';
import { JSAContext } from '../../context/JSAContext';

const JSABasicInfo = () => {
  // Placeholder terms
  const terms = ['one', 'two', 'three'];

  // Site Coordinates
  const { jsa } = useContext(JSAContext);
  const { token } = useContext(GlobalContext);
  const [lat, setLat] = useState('1.1');
  const [lon, setLon] = useState('1.1');
  const handleLatChange = (event: { target: { value: string; }; }) => {
    setLat(event.target.value);
  };
  const handleLonChange = (event: { target: { value: string; }; }) => {
    setLon(event.target.value);
  };

  // Site name AKA location
  const locationSearchItems = terms.map((term) => ({ label: term, value: term }));
  const [locationTerm, setLocationTerm] = useState<string>('');
  const [location, setLocation] = useState(jsa?.site?.name as string);
  const handleLocation = (option: string) => {
    jsa?.handleSiteChange(token, option, jsa.site?.id as unknown as string | string);
    setLocation(option);
  };

  // Customer name AKA org name
  const customerSearchItems = terms.map((term) => ({ label: term, value: term }));
  const [customerTerm, setCustomerTerm] = useState<string>('');
  const [customer, setCustomer] = useState(jsa?.org?.name as string);
  const handleCustomer = (option: string) => {
    jsa?.handleOrgChange(token, option, jsa.org?.id as unknown as string);
    setCustomer(option);
  };

  return (
    <div className="container">
      <div>
        <label className="label" htmlFor="locOfWork">Location of Work</label>
        <br />
        <Dropdown
          placeholder="Select location"
          options={locationSearchItems.filter((item) => item.label.toLowerCase()
            .includes(locationTerm.toLowerCase()))}
          onSelect={(option) => { handleLocation(option.label); }}
          value={location as string}
          searchValue={locationTerm}
          onSearchChange={setLocationTerm}
          className="small-flo-dropdown"
        />
      </div>
      <div>
        <label className="label" htmlFor="customerName">Customer Name</label>
        <br />
        <Dropdown
          className="small-flo-dropdown"
          placeholder="Select customer"
          options={
            customerSearchItems.filter((item) => item.label.toLowerCase().includes(
              customerTerm.toLowerCase()
            ))
          }
          onSelect={(option) => { setCustomer(option.label); handleCustomer(option.label); }}
          value={customer as string}
          searchValue={customerTerm}
          onSearchChange={setCustomerTerm}
        />
      </div>
      <div>
        <label className="label" htmlFor="locCoords">Location Coordinates</label>
        <br />
        <DualInput
          leftInput={{
            placeholder: '0.0',
            suffix: 'lat',
            value: lat,
            onChange: handleLatChange
          }}
          rightInput={{
            placeholder: '0.0',
            suffix: 'lon',
            value: lon,
            onChange: handleLonChange
          }}
          width={365}
          containerClass="small-dual-flo-input"
        />
      </div>
    </div>
  );
};

export default JSABasicInfo;
