import { ReactNode } from 'react';
import { WorkOrderProvider } from './context/WorkOrderContext';
import { GlobalProvider } from './context/GlobalContext';
import { JSAProvider } from './context/JSAContext';

export const AppProviders = ({ children }: { children: ReactNode }) => (
  <GlobalProvider>
    <WorkOrderProvider>
      <JSAProvider>{children}</JSAProvider>
    </WorkOrderProvider>
  </GlobalProvider>
);

export default AppProviders;
