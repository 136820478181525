const ControlsEnums = Object.freeze({
  confinedSpaceEntryProcedure: 'Confined Space Entry Procedure',
  useAppropriatePPE: 'Appropriate PPE for Task (includes gloves, face shields, FR\'s, etc.)',
  useOfBarricades: 'Use of Barricades/Restrict Access',
  reviewEmergencyPlan: 'Review Emergency Action Plan',
  fourGasMonitorUse: '4-Gas Monitor Use',
  spillKitOrContainment: 'Spill Kit/Containment',
  drinkFluids: 'Drinking Water/Fluids',
  fallProtectionOrLadder: 'Fall Protection/Ladder',
  equipmentCoolDown: 'Equipment Cool Down',
  electricianNeeded: 'Electrician Needed',
  depressurize: 'De-Pressurize',
  fireExtinguisher: 'Fire Extinguisher',
  lockOutTagOut: 'Lockout/Tag-out',
  otherDescription: 'Other'
});

export default ControlsEnums;
