import { Card } from 'react-bootstrap';
import { Input } from '@flogistix/flo-ui';
import { useContext, useEffect, useState } from 'react';
import { WorkOrderContext } from '../../context/WorkOrderContext';
import './Mileage.scss';

const Mileage = () => {
  const { workOrder } = useContext(WorkOrderContext);
  const [show, setShow] = useState(false);
  const [mileageStart, setMileageStart] = useState(workOrder.mileage_start as number);
  const [mileageEnd, setMileageEnd] = useState(workOrder.mileage_end as number);
  const [mileageTotal, setMileageTotal] = useState<number>(0);

  const handleOpen = () => setShow(!show);

  useEffect(() => {
    if (mileageEnd && mileageStart) {
      const total = +mileageEnd - +mileageStart;
      if (total < 0) setMileageTotal(0);
      else {
        setMileageTotal(total);
      }
    } else setMileageTotal(0);
  }, [mileageStart, mileageEnd]);

  useEffect(() => {
    workOrder.mileage_start = mileageStart;
    workOrder.mileage_end = mileageEnd;
  }, [mileageTotal]);

  return (
    <Card className={show ? 'mileage-button-active' : 'mileage-button'}>
      <Card className="mileage-card" onClick={handleOpen}>
        <h6>Mileage</h6>
      </Card>
      { show ? (
        <div className="mileage-dropdown-div">
          <div className="mileage-div">
            <Input
              type="number"
              fixedWidth="100%"
              placeholder="Start"
              className="mileage-input"
              value={mileageStart}
              onChange={(e) => setMileageStart(+e.target.value)}
            />
            <label className="padding-label" />
            <Input
              type="number"
              fixedWidth="100%"
              placeholder="End"
              className="mileage-input"
              value={mileageEnd}
              onChange={(e) => setMileageEnd(+e.target.value)}
            />
          </div>
          <label>
            Total:
            {' '}
            {mileageTotal}
            {' '}
            miles
          </label>
        </div>
      ) : <div /> }
    </Card>
  );
};
export default Mileage;
