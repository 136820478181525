import { Spin, Flex } from 'antd';
import './App.scss';

const contentStyle: React.CSSProperties = {
  padding: 50,
  background: 'rgba(0, 0, 0, 0.1)',
  borderRadius: 4
};

const content = <div style={contentStyle} />;

const Loading: React.FC = () => (
  <Flex gap="middle" vertical className="loading">
    <Spin tip="Loading">
      {content}
    </Spin>
  </Flex>
);

export default Loading;
