import floworksLogo from '../../assets/floworksblue.png';
import flogistixLogo from '../../assets/FlogistixLogo.png';
import './Header.scss';

const Header = () => (
  <div className="header-page">
    <img className="header-image" src={floworksLogo} alt="" />
    <img className="header-image" src={flogistixLogo} alt="" />
  </div>
);

export default Header;
