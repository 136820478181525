import Dexie, { EntityTable } from 'dexie';
import { WorkOrderWithoutId } from '../pages/ModifyWorkOrder/WorkOrderTypes';
import JSAWithoutId from '../pages/ModifyJSA/JSATypes';

export type JsonType = {
  [key: string]: string | number | File | object | JsonType | JsonType[] | null;
};

export type Request = {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  headers: { [key: string]: string };
  payload: JsonType;
  endpoint: string;
}

export interface Unit {
  id: string;
  skidId: string;
}

export interface Transaction {
  id?: number;
  sequence?: number;
  queueId: string;
  request: Request;
  response?: JsonType;
  status?: 'queued' | 'processing' | 'retrying' | 'failed' | 'completed';
  attempts?: number;
  createdAt?: Date;
  updatedAt?: Date;
  processingStartedAt?: Date;
  completedAt?: Date;
  type?: string;
}

export interface JSA extends JSAWithoutId {
  id?: number;
}

export interface WorkOrder extends WorkOrderWithoutId {
  id?: number;
}

export const db = new Dexie('WorkorderDB') as Dexie & {
  transactions: EntityTable<Transaction, 'id'>;

  jsas: EntityTable<JSA, 'id'>;

  workOrders: EntityTable<WorkOrder, 'id'>;

  units: EntityTable<Unit, 'id'>;
};

db.version(1).stores({
  transactions: `++id,
                sequence,
                queueId, 
                request, 
                response, 
                status, 
                attempts, 
                createdAt, 
                updatedAt, 
                processingStartedAt,
                completedAt,
                type`,
  jsas: `++id,
        parentId,
        version,
        createdAt,
        createdBy,
        updatedAt,
        updatedBy,
        deleted,
        lastAct,
        flogistix_id,
        mechanic,
        truck, 
        org, 
        site,
        description_of_work,
        emergency_evacuation_instructions,
        unsafe_surroundings,
        contacts,
        controls,
        hazards,
        signatures,
        status,
        created`,
  workOrders: `++id, 
              parentId,
              version,
              createdAt,
              createdBy,
              updatedAt,
              updatedBy,
              deleted,
              lastAct,
              jsa_id, 
              mechanic_id,
              application_type, 
              unit_id,
              computer_id,
              truck_id,
              customer_contact_id,
              lease_id,
              work_order_type,
              owner,
              start_time,
              end_time,
              mileage_start,
              mileage_end,
              start_gps,
              end_gps,
              county,
              state,
              billable,
              warranty_work,
              afe,
              approval_code,
              billable_hours,
              billable_miles,
              part_id,
              created`,
  units: `++id,
          skidId`
});
