import { Card } from 'react-bootstrap';
import { Input } from '@flogistix/flo-ui';
import { useState } from 'react';
import './H2STesting.scss';

const H2STesting = () => {
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(!show);
  return (
    <Card className={show ? 'h2s-button-active' : 'h2s-button'}>
      <Card className="h2s-card" onClick={handleOpen}>
        <h6>H2S Testing</h6>
      </Card>
      { show ? (
        <div className="h2s-dropdown-div">
          <div>
            <label className="h2s-label">Insert Value from Test:</label>
          </div>
          <div>
            <Input type="number" fixedWidth="35%" className="h2s-input" />
          </div>
        </div>
      ) : <div />}
    </Card>
  );
};

export default H2STesting;
