import { Routes, Route } from 'react-router-dom';
import SideNav from './components/SideNav/SideNav';
import NavItems from './components/SideNav/SideNavData';
import Home from './pages/Home';
import WorkOrder from './pages/WorkOrderDashboard';
import NewWorkOrder from './pages/NewWorkOrder';
import './App.scss';

const AppRouter = () => (
  <div className="container-page">
    <div className="sidenav">
      <SideNav navItems={NavItems} />
    </div>
    <div className="main">
      <Routes>
        <Route path="/">
          <Route path="home" element={<Home />} />
          <Route path="workOrder/basicInfo" element={<NewWorkOrder />} />
          <Route path="workOrdersHistory" element={<WorkOrder />} />
        </Route>
      </Routes>
    </div>
  </div>
);

export default AppRouter;
