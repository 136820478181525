import {
  useState, createContext, useEffect, useMemo, ReactNode
} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { addOrUpdateUnits } from '../dexie/operations';
import getUnits from '../services/getUnits';
import { Unit } from '../dexie/db';

interface GlobalContextShape {
  token: string;
  unitIds: string[];
}

const defaultGlobalContext: GlobalContextShape = {
  token: '',
  unitIds: []
};

export const GlobalContext = createContext<GlobalContextShape>(defaultGlobalContext);

export const GlobalProvider = ({ children }:{children:ReactNode}) => {
  const [token, setToken] = useState('');
  const [unitIds, setUnitIds] = useState(['']);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      (async () => {
        const accessToken = await getAccessTokenSilently();
        setToken(accessToken);
      })();
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  const fetchUnitIds = async (units: Promise<[Unit]>) => {
    const unit_ids = await units.then((value) => value.map((unit) => unit.skidId));
    setUnitIds(unit_ids);
  };

  useEffect(() => {
    if (token) {
      const units = getUnits(token);
      fetchUnitIds(units as unknown as Promise<[Unit]>);
      units.then((value) => value.map((unit: Unit) => addOrUpdateUnits(unit)));
    }
  }, [token]);

  const providerValue = useMemo(
    () => ({
      token,
      unitIds
    }),
    [
      token,
      unitIds
    ]
  );
  return (
    <GlobalContext.Provider value={providerValue}>
      {children}
    </GlobalContext.Provider>
  );
};
