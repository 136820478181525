import { useEffect, useState } from 'react';
import { Input, Button } from '@flogistix/flo-ui';
import './GasEngineChecklist.scss';
import { CloseButton } from 'react-bootstrap';

interface CylinderProps {
  id: number;
  deleteCylinder: (id: number) => void;
}

type Cylinder = {
  id: number;
  deleteCylinder: (id: number) => void;
}

const Cylinders: React.FC<CylinderProps> = ({ id, deleteCylinder }) => (
  <div>
    <label className="ge-label">
      Cylinder
      {' '}
      {id}
      <CloseButton className="close-button" onClick={() => deleteCylinder(id)} />
    </label>
    <br />
    <Input className="ge-input" fixedWidth="110px" />
  </div>
);

const RecordEngineCompression = () => {
  const [cylinders, setCylinders] = useState<CylinderProps[]>([]);

  const addCylinder = () => {
    const newCylinder = { id: cylinders.length + 2 } as Cylinder;
    setCylinders([...cylinders, newCylinder]);
  };

  const handleClose = (id: number) => {
    const index = cylinders.findIndex((cylinder) => cylinder.id === id);
    const cylindersCopy = [...cylinders as Cylinder[]];
    cylindersCopy.splice(index, 1);
    setCylinders(cylindersCopy);
  };

  return (
    <div className="rec-div">
      <h6 className="ge-header">Record Engine Compression</h6>
      <div className="cylinder-div">
        <div className="div">
          <label className="ge-label">Cylinder 1</label>
          <br />
          <Input className="ge-input" fixedWidth="110px" />
        </div>
        {cylinders.map((cylinder) => (
          <div className="div">
            <Cylinders key={cylinder.id} id={cylinder.id} deleteCylinder={handleClose} />
          </div>
        ))}
      </div>
      <Button className="ge-button" onClick={addCylinder}>+ Add New Cylinder</Button>
    </div>
  );
};

export default RecordEngineCompression;
