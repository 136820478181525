import { Card } from 'react-bootstrap';
import '../../pages/NewWorkOrder.scss';

const UnitReadings = () => (
  <Card className="full-button">
    <h6>Unit Readings</h6>
  </Card>
);

export default UnitReadings;
