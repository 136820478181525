import { v4 as uuidv4 } from 'uuid';
import { JSA_DEV_API_URL } from '../shared/url';
import { JsonType, Transaction } from '../dexie/db';
import { createTransaction, getVersionByJSAId } from '../dexie/operations';

const contactInit = {
  area_manager: { name: '', phone_number: '' },
  local_fire: { name: '', phone_number: '' },
  EHS_manager: { name: '', phone_number: '' },
  local_hospital: { name: '', phone_number: '' },
  local_sheriff: { name: '', phone_number: '' },
  other_contacts: []
};

const controlInit = {
  confined_space_entry_procedure: false,
  use_appropriate_ppe: false,
  use_of_barricades: false,
  review_emergency_plan: false,
  four_gas_monitor_use: false,
  spill_kit_or_containment: false,
  drink_fluids: false,
  fall_protection_or_ladder: false,
  equipment_cool_down: false,
  electrician_needed: false,
  depressurize: false,
  fire_extinguisher: false,
  lock_out_tag_out: false,
  other_description: ''
};

const hazardInit = {
  body_position: false,
  pinch_crush_or_striking_hazard: false,
  laceration_potential: false,
  handling_lifting_hazard: false,
  ladders_or_fall_from_elevation: false,
  extreme_weather: false,
  heat_or_hypothermia: false,
  h2s_or_so2_exposure: false,
  hot_cold_equipment_surfaces: false,
  hazardous_chemical_exposure: false,
  hot_liquids_gases: false,
  excavation_hazards: false,
  slick_surfaces: false,
  crane_elevated_load_rigging: false,
  equipment_isolation_hazard: false,
  fire_explosion_potential: false,
  electrical_shock: false,
  confined_spaces_entry: false,
  adjacent_work: false,
  other_description: '',
  plan: {
    first_hazard: { hazard: undefined, description: undefined },
    second_hazard: { hazard: undefined, description: undefined },
    third_hazard: { hazard: undefined, description: undefined }
  }
};

const emergencyEvacuationInstructionsInit = {
  weather_condition: '',
  wind: {
    blowing_from: '',
    blowing_to: '',
    wind_speed: 0
  },
  primary_muster_point: '',
  secondary_muster_point: ''
};

interface Controls {
  confined_space_entry_procedure: boolean;
  use_appropriate_ppe: boolean;
  use_of_barricades: boolean;
  review_emergency_plan: boolean;
  four_gas_monitor_use: boolean;
  spill_kit_or_containment: boolean;
  drink_fluids: boolean;
  fall_protection_or_ladder: boolean;
  equipment_cool_down: boolean;
  electrician_needed: boolean;
  depressurize: boolean;
  fire_extinguisher: boolean;
  lock_out_tag_out: boolean;
  other_description?: string;
}

interface Plan {
  hazard?: string;
  description?: string;
}

interface HazardPlan {
  first_hazard?: Plan;
  second_hazard?: Plan;
  third_hazard?: Plan;
}

interface Hazards {
  body_position: boolean;
  pinch_crush_or_striking_hazard: boolean;
  laceration_potential: boolean;
  handling_lifting_hazard: boolean;
  ladders_or_fall_from_elevation: boolean;
  extreme_weather: boolean;
  heat_or_hypothermia: boolean;
  h2s_or_so2_exposure: boolean;
  hot_cold_equipment_surfaces: boolean;
  hazardous_chemical_exposure: boolean;
  hot_liquids_gases: boolean;
  excavation_hazards: boolean;
  slick_surfaces: boolean;
  crane_elevated_load_rigging: boolean;
  equipment_isolation_hazard: boolean;
  fire_explosion_potential: boolean;
  electrical_shock: boolean;
  confined_spaces_entry: boolean;
  adjacent_work: boolean;
  other_description?: string;
  plan?: HazardPlan;
}

interface Mechanic {
  id?: number | string;
  name?: string;
}

interface Truck {
  id?: number | string;
  name?: string;
}

interface Org {
  id?: number | string;
  name?: string;
}

interface Site {
  id?: number | string;
  name?: string;
}

interface UnsafeSurrounding {
  is_unsafe: boolean;
  comments?: string;
}

interface Wind {
  blowing_from?: string;
  blowing_to?: string;
  wind_speed?: number;
}

interface EmergencyEvacuationInstructions {
  weather_condition?: string
  wind?: Wind
  primary_muster_point?: string
  secondary_muster_point?: string
}

interface Contact {
  name?: string;
  phone_number?: string;
}

interface Contacts {
  area_manager?: Contact;
  local_fire?: Contact;
  EHS_manager?: Contact;
  local_hospital?: Contact;
  local_sheriff?: Contact;
  other_contacts?: Contact[];
}

export class JSA {
  id?: string;

  parentId?: string;

  version?: number;

  createdAt?: Date;

  createdBy?: string;

  updatedAt?: Date;

  updatedBy?: string;

  deleted?: boolean;

  lastAct?: string;

  flogistix_id?: string;

  mechanic?: Mechanic;

  truck?: Truck;

  org?: Org;

  site?: Site;

  description_of_work?: string;

  emergency_evacuation_instructions?: EmergencyEvacuationInstructions;

  unsafe_surroundings?: UnsafeSurrounding;

  contacts?: Contacts;

  controls?: Controls;

  hazards?: Hazards;

  signatures?: string[];

  status?: string;

  created?: boolean;

  constructor() {
    this.id = '';
    this.deleted = false;
    this.mechanic = { id: '', name: '' };
    this.truck = { id: '', name: '' };
    this.org = { id: '', name: '' };
    this.site = { id: '', name: '' };
    this.unsafe_surroundings = { is_unsafe: false, comments: '' };
    this.contacts = contactInit;
    this.controls = controlInit;
    this.hazards = hazardInit;
    this.signatures = [];
    this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
  }

  async createJSA(token: string) {
    this.id = uuidv4();
    this.status = 'Created';
    this.created = true;
    this.createdAt = new Date();
    if (this.id) {
      const transaction = {
        queueId: `${this.id}`,
        type: 'jsa',
        request: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: this as object,
          endpoint: `${JSA_DEV_API_URL}/jsa`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async handleMechanicChange(token: string, name: string, id: string | number) {
    if (!this.created) return;
    if (!this.mechanic) this.mechanic = {};
    this.mechanic = { name, id };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { id: `${id}`, name: `${name}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/mechanic`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleTruckChange(token: string, name: string, id: string | number) {
    if (!this.created) return;
    if (!this.truck) this.truck = {};
    this.truck = { name, id };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { id: `${id}`, name: `${name}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/truck`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleOrgChange(token: string, name: string, id: number | string) {
    if (!this.created) return;
    if (!this.org) this.org = {};
    this.org = { name, id };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { id: `${id}`, name: `${name}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/org`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleSiteChange(token: string, name: string, id: string | number) {
    if (!this.created) return;
    if (!this.site) this.site = {};
    this.site = { name, id };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { id: `${id}`, name: `${name}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/site`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleDescriptionOfWork(token: string, descriptionOfWork: string) {
    if (!this.created) return;
    this.description_of_work = descriptionOfWork;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { description_of_work: `${descriptionOfWork}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/descriptionOfWork`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWeatherCondition(token: string, weatherCondition: string) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    this.emergency_evacuation_instructions.weather_condition = weatherCondition;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { weather_condition: `${weatherCondition}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/weatherCondition`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWindBlowingFrom(token: string, WindBlowingFrom: string) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    if (!this.emergency_evacuation_instructions.wind) { // Super redundant, Blame the linter :P
      this.emergency_evacuation_instructions.wind = emergencyEvacuationInstructionsInit.wind;
    }
    this.emergency_evacuation_instructions.wind.blowing_from = WindBlowingFrom;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { blowing_from: `${WindBlowingFrom}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/wind/blowingFrom`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWindBlowingTo(token: string, WindBlowingTo: string) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    if (!this.emergency_evacuation_instructions.wind) { // Super redundant, Blame the linter :P
      this.emergency_evacuation_instructions.wind = emergencyEvacuationInstructionsInit.wind;
    }
    this.emergency_evacuation_instructions.wind.blowing_to = WindBlowingTo;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { blowing_to: `${WindBlowingTo}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/wind/blowingTo`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWindSpeed(token: string, WindSpeed: number) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    if (!this.emergency_evacuation_instructions.wind) { // Redundant, Blame the linter :P
      this.emergency_evacuation_instructions.wind = emergencyEvacuationInstructionsInit.wind;
    }
    this.emergency_evacuation_instructions.wind.wind_speed = WindSpeed;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { wind_speed: `${WindSpeed}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/wind/windSpeed`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handlePrimaryMusterPoint(token: string, primaryMusterPoint: string) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    this.emergency_evacuation_instructions.primary_muster_point = primaryMusterPoint;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { primary_muster_point: `${primaryMusterPoint}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/primaryMusterPoint`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleSecondaryMusterPoint(token: string, secondaryMusterPoint: string) {
    if (!this.created) return;
    if (!this.emergency_evacuation_instructions) {
      this.emergency_evacuation_instructions = emergencyEvacuationInstructionsInit;
    }
    this.emergency_evacuation_instructions.secondary_muster_point = secondaryMusterPoint;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { secondary_muster_point: `${secondaryMusterPoint}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/emergencyEvacuationInstructions/secondaryMusterPoint`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleUnsafeSurroundings(token: string, is_unsafe: boolean, comments: string) {
    if (!this.created) return;
    if (!this.unsafe_surroundings) this.unsafe_surroundings = { is_unsafe: false, comments: '' };
    this.unsafe_surroundings.is_unsafe = is_unsafe;
    this.unsafe_surroundings.comments = comments;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { is_unsafe: `${is_unsafe}`, comments: `${comments}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/unsafeSurroundings`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleFlogistixId(token: string, flogistixId: string) {
    if (!this.created) return;
    this.flogistix_id = flogistixId;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { flogistix_id: `${flogistixId}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/flogistixId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleAreaManager(token: string, name: string, phone_number: string) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    if (!this.contacts.area_manager) this.contacts.area_manager = {};
    this.contacts.area_manager.name = name;
    this.contacts.area_manager.phone_number = phone_number;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { name: `${name}`, phone_number: `${phone_number}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/areaManager`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleLocalFire(token: string, name: string, phone_number: string) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    if (!this.contacts.local_fire) this.contacts.local_fire = {};
    this.contacts.local_fire.name = name;
    this.contacts.local_fire.phone_number = phone_number;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { name: `${name}`, phone_number: `${phone_number}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/localFire`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleEHSManager(token: string, name: string, phone_number: string) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    if (!this.contacts.EHS_manager) this.contacts.EHS_manager = {};
    this.contacts.EHS_manager.name = name;
    this.contacts.EHS_manager.phone_number = phone_number;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { name: `${name}`, phone_number: `${phone_number}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/EHSManager`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleLocalHospital(token: string, name: string, phone_number: string) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    if (!this.contacts.local_hospital) this.contacts.local_hospital = {};
    this.contacts.local_hospital.name = name;
    this.contacts.local_hospital.phone_number = phone_number;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { name: `${name}`, phone_number: `${phone_number}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/localHospital`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleLocalSheriff(token: string, name: string, phone_number: string) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    if (!this.contacts.local_sheriff) this.contacts.local_sheriff = {};
    this.contacts.local_sheriff.name = name;
    this.contacts.local_sheriff.phone_number = phone_number;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { name: `${name}`, phone_number: `${phone_number}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/localSheriff`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleOtherContact(token: string, other_contacts: Contact[]) {
    if (!this.created) return;
    if (!this.contacts) this.contacts = contactInit;
    this.contacts.other_contacts = other_contacts;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { other_contacts },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/contacts/otherContacts`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsConfinedSpaceEntryProcedure(token: string, confined_space_entry_procedure: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.confined_space_entry_procedure = confined_space_entry_procedure;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { confined_space_entry_procedure: `${confined_space_entry_procedure}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/confinedSpaceEntryProcedure`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsUseAppropriatePPE(token: string, use_appropriate_ppe: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.use_appropriate_ppe = use_appropriate_ppe;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { use_appropriate_ppe: `${use_appropriate_ppe}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/useAppropriatePPE`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsUseOfBarricades(token: string, use_of_barricades: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.use_of_barricades = use_of_barricades;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { use_of_barricades: `${use_of_barricades}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/useOfBarricades`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsReviewEmergencyPlan(token: string, review_emergency_plan: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.review_emergency_plan = review_emergency_plan;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { review_emergency_plan: `${review_emergency_plan}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/reviewEmergencyPlan`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsFourGasMonitorUse(token: string, four_gas_monitor_use: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.four_gas_monitor_use = four_gas_monitor_use;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { four_gas_monitor_use: `${four_gas_monitor_use}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/fourGasMonitorUse`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsSpillKitOrContainment(token: string, spill_kit_or_containment: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.spill_kit_or_containment = spill_kit_or_containment;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { spill_kit_or_containment: `${spill_kit_or_containment}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/spillKitOrContainment`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsDrinkFluids(token: string, drink_fluids: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.drink_fluids = drink_fluids;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { drink_fluids: `${drink_fluids}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/drinkFluids`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsFallProtectionOrLadder(token: string, fall_protection_or_ladder: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.fall_protection_or_ladder = fall_protection_or_ladder;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { fall_protection_or_ladder: `${fall_protection_or_ladder}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/fallProtectionOrLadder`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  // Handle equipment_cool_down https://dev-jsa.api.axil.ai/jsa/{id}/controls/equipmentCoolDown
  async handleControlsEquipmentCoolDown(token: string, equipment_cool_down: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.equipment_cool_down = equipment_cool_down;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { equipment_cool_down: `${equipment_cool_down}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/equipmentCoolDown`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handlecontrolsElectricianNeeded(token: string, electrician_needed: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.electrician_needed = electrician_needed;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { electrician_needed: `${electrician_needed}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/electricianNeeded`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsDepressurize(token: string, depressurize: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.depressurize = depressurize;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { depressurize: `${depressurize}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/depressurize`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsFireExtinguisher(token: string, fire_extinguisher: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.fire_extinguisher = fire_extinguisher;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { fire_extinguisher: `${fire_extinguisher}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/fireExtinguisher`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsLockOutTagOut(token: string, lock_out_tag_out: boolean) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.lock_out_tag_out = lock_out_tag_out;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { lock_out_tag_out: `${lock_out_tag_out}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/lockOutTagOut`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleControlsOtherDescription(token: string, other_description: string) {
    if (!this.created) return;
    if (!this.controls) this.controls = controlInit;
    this.controls.other_description = other_description;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { other_description: `${other_description}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/controls/otherDescription`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsBodyPosition(token: string, body_position: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.body_position = body_position;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { body_position: `${body_position}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/bodyPosition`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsPinchCrushOrStrikingHazard(token: string, pinch_crush_or_striking_hazard: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.pinch_crush_or_striking_hazard = pinch_crush_or_striking_hazard;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { pinch_crush_or_striking_hazard: `${pinch_crush_or_striking_hazard}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/pinchCrushOrStrikingHazard`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsLacerationPotential(token: string, laceration_potential: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.laceration_potential = laceration_potential;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { laceration_potential: `${laceration_potential}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/lacerationPotential`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsHandlingLifting(token: string, handling_lifting_hazard: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.handling_lifting_hazard = handling_lifting_hazard;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { handling_lifting_hazard: `${handling_lifting_hazard}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/handlingLifting`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsLaddersOrFallFromElevation(token: string, ladders_or_fall_from_elevation: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.ladders_or_fall_from_elevation = ladders_or_fall_from_elevation;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { ladders_or_fall_from_elevation: `${ladders_or_fall_from_elevation}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/laddersOrFallFromElevation`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  // handle extreme_weather https://dev-jsa.api.axil.ai/jsa/{id}/hazards/extremeWeather
  async handleHazardsExtremeWeather(token: string, extreme_weather: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.extreme_weather = extreme_weather;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { extreme_weather: `${extreme_weather}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/extremeWeather`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsHeatOrHypothermia(token: string, heat_or_hypothermia: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.heat_or_hypothermia = heat_or_hypothermia;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { heat_or_hypothermia: `${heat_or_hypothermia}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/heatOrHypothermia`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsH2SorSO2exposure(token: string, h2s_or_so2_exposure: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.h2s_or_so2_exposure = h2s_or_so2_exposure;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { h2s_or_so2_exposure: `${h2s_or_so2_exposure}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/H2SorSO2exposure`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsHotColdEquipmentSurfaces(token: string, hot_cold_equipment_surfaces: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.hot_cold_equipment_surfaces = hot_cold_equipment_surfaces;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hot_cold_equipment_surfaces: `${hot_cold_equipment_surfaces}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/hotColdEquipmentSurfaces`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsHazardousChemicalExposure(token: string, hazardous_chemical_exposure: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.hazardous_chemical_exposure = hazardous_chemical_exposure;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hazardous_chemical_exposure: `${hazardous_chemical_exposure}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/hazardousChemicalExposure`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsHotLiquidsGases(token: string, hot_liquids_gases: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.hot_liquids_gases = hot_liquids_gases;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hot_liquids_gases: `${hot_liquids_gases}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/hotLiquidsGases`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsExcavationHazards(token: string, excavation_hazards: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.excavation_hazards = excavation_hazards;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { excavation_hazards: `${excavation_hazards}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/excavationHazards`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsSlickSurfaces(token: string, slick_surfaces: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.slick_surfaces = slick_surfaces;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { slick_surfaces: `${slick_surfaces}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/slickSurfaces`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsCraneElevatedLoadRigging(token: string, crane_elevated_load_rigging: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.crane_elevated_load_rigging = crane_elevated_load_rigging;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { crane_elevated_load_rigging: `${crane_elevated_load_rigging}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/craneElevatedLoadRigging`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  // handle equipment_isolation_hazard https://dev-jsa.api.axil.ai/jsa/{id}/hazards/equipmentIsolationHazard
  async handleHazardsEquipmentIsolationHazard(token: string, equipment_isolation_hazard: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.equipment_isolation_hazard = equipment_isolation_hazard;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { equipment_isolation_hazard: `${equipment_isolation_hazard}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/equipmentIsolationHazard`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsFireExplosionPotential(token: string, fire_explosion_potential: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.fire_explosion_potential = fire_explosion_potential;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { fire_explosion_potential: `${fire_explosion_potential}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/fireExplosionPotential`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsElectricalShock(token: string, electrical_shock: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.electrical_shock = electrical_shock;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { electrical_shock: `${electrical_shock}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/electricalShock`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsConfinedSpacesEntry(token: string, confined_spaces_entry: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.confined_spaces_entry = confined_spaces_entry;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { confined_spaces_entry: `${confined_spaces_entry}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/confinedSpacesEntry`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsAdjacentWork(token: string, adjacent_work: boolean) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.adjacent_work = adjacent_work;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { adjacent_work: `${adjacent_work}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/adjacentWork`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleHazardsOtherDescription(token: string, other_description: string) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    this.hazards.other_description = other_description;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { other_description: `${other_description}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/otherDescription`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleFirstHazardPlan(token: string, hazard: string, description: string) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    if (!this.hazards.plan) this.hazards.plan = hazardInit.plan; // Redundant, blame the linter :P
    this.hazards.plan.first_hazard = { hazard, description };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hazard: `${hazard}`, description: `${description}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/plan/firstHazard`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleSecondHazardPlan(token: string, hazard: string, description: string) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    if (!this.hazards.plan) this.hazards.plan = hazardInit.plan; // Redundant, blame the linter :P
    this.hazards.plan.second_hazard = { hazard, description };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hazard: `${hazard}`, description: `${description}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/plan/secondHazard`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleThirdHazardPlan(token: string, hazard: string, description: string) {
    if (!this.created) return;
    if (!this.hazards) this.hazards = hazardInit;
    if (!this.hazards.plan) this.hazards.plan = hazardInit.plan; // Redundant, blame the linter :P
    this.hazards.plan.third_hazard = { hazard, description };
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { hazard: `${hazard}`, description: `${description}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/hazards/plan/thirdHazard`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleSignatures(token: string, signatures: string[]) {
    if (this.created) return;
    this.signatures = signatures;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { signatures: `${signatures}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/signatures`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleStatus(token: string, status: string) {
    if (this.created) return;
    this.status = status;
    const transaction = {
      queueId: `${this.id}`,
      type: 'jsa',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { status: `${status}` },
        endpoint: `${JSA_DEV_API_URL}/jsa/${this.id}/status`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }
}

export default JSA;
