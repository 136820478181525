import { UNITS_API_URL } from '../shared/url';

const getUnits = async (token: string): Promise<[]> => {
  try {
    const response = await fetch(`${UNITS_API_URL}/units/Flogistix/units`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    });
    if (!response.ok) {
      throw new Error(`Response status: ${response.status}`);
    }
    const units = await response.json();
    return units;
  } catch (err) {
    throw new Error(`Error: ${err}`);
  }
};

export default getUnits;
