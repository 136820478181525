import { useContext, useState } from 'react';
import { Button } from '@flogistix/flo-ui';
import Modal from 'react-bootstrap/Modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import JSABasicInfo from './JSABasicInfo';
import JSAContact from './JSAContact';
import JSADescriptionOfWork from './JSADescriptionOfWork';
import JSAEmergencyEvacuation from './JSAEmergencyEvacuation';
import JSAHazards from './JSAHazards';
import JSAControlsAndPractices from './JSAControlsAndPractices';
import { JSAContext } from '../../context/JSAContext';
import { GlobalContext } from '../../context/GlobalContext';
import './JSA.scss';

const JsaTabs = () => (
  <Tabs defaultActiveKey="basic" id="fill-tab-example" variant="pills" className="jsa-nav" fill>
    <Tab eventKey="basic" title="Basic Information">
      <JSABasicInfo />
      <hr />
      <JSAContact />
      <hr />
      <JSADescriptionOfWork />
      <hr />
      <JSAEmergencyEvacuation />
    </Tab>
    <Tab eventKey="hazardPlanning" title="Hazard Planning">
      <JSAHazards />
    </Tab>
    <Tab eventKey="controls" title="Controls & Practices">
      <JSAControlsAndPractices />
    </Tab>
    <Tab eventKey="siteConditions" title="Site Conditions">
      Tab for site conditions
    </Tab>
    <Tab eventKey="teamMembers" title="Team Members">
      Tab for team member signatures
    </Tab>
  </Tabs>
);

const JSAForm = () => {
  const [show, setShow] = useState(false);
  const { jsa } = useContext(JSAContext);
  const { token } = useContext(GlobalContext);

  const handleClose = () => setShow(false);
  const newJSA = () => {
    setShow(true);
    if (jsa?.status !== 'Created') {
      jsa?.createJSA(token);
    }
  };

  return (
    <>
      <Button fixedWidth="160px" onClick={newJSA}>
        Job Safety Analysis
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-90w"
        className="jsa-modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <img src="/Icon.svg" alt="Close JSA" className="JSAIcon" />
        </Modal.Header>
        <Modal.Body className="JSA-body">
          <Modal.Title>Job Safety Analysis</Modal.Title>
          Before you continue, please fill out the job safety analysis
          <JsaTabs />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JSAForm;
