import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AppProviders } from './AppProviders';
import sendMessageToSW from './shared/utils';

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

serviceWorkerRegistration.register();

const AppContainer = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      sendMessageToSW({ type: 'PROCESS_CACHED_REQUESTS' });
    }, 30000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Auth0Provider
      useRefreshTokens
      domain="axil.auth0.com"
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID as string}
      cacheLocation="localstorage"
      authorizationParams={{
        scope: 'openid profile email',
        redirect_uri: window.location.origin,
        audience: 'https://api.axil.ai'
      }}
    >
      <BrowserRouter>
        <AppProviders>
          <App />
        </AppProviders>
      </BrowserRouter>
    </Auth0Provider>
  );
};

root.render(<AppContainer />);
