const HazardsEnums = Object.freeze({
  bodyPosition: 'Body Position',
  pinchCrushOrStrikingHazard: 'Pinch, Crush, or Striking Hazard',
  lacerationPotential: 'Sharp Edges/Laceration Potential',
  handlingLiftingHazard: 'Material Handling/Lifting Hazard',
  laddersOrFallFromElevation: 'Ladders/Fall from Elevation',
  extremeWeather: 'Extreme Weather',
  heatOrHypothermia: 'Heat-related Illness/Hypothermia',
  h2sOrSo2Exposure: 'H2S/SO2 Exposure',
  hotColdEquipmentSurfaces: 'Hot/Cold Equipment Surfaces',
  hazardousChemicalExposure: 'Hazardous Chemical Exposure',
  hotLiquidsGases: 'Hot liquids / gasses',
  excavationHazards: 'Excavation Hazards',
  slickSurfaces: 'Slips, Trips, Falls, Slick Surfaces',
  craneElevatedLoadRigging: 'Crane Use, Elevated Load, Rigging',
  equipmentIsolationHazard: 'Equipment/Isolation Hazards',
  fireExplosionPotential: 'Fire and Explosion Potential',
  electricalShock: 'Electrical Shock',
  confinedSpacesEntry: 'Confined Spaces Entry',
  adjacentWork: 'Adjacent Work being Performed by Other Crews on Location',
  other: 'Other'
});

export default HazardsEnums;
