import { Card, Form } from 'react-bootstrap';
import { useState, useContext, useEffect } from 'react';
import { WorkOrderContext } from '../../context/WorkOrderContext';
import './BillInfo.scss';

const BillInfo = () => {
  const { workOrder } = useContext(WorkOrderContext);
  const [show, setShow] = useState(false);
  const [billToCustomer, setBillToCustomer] = useState(workOrder.billable);
  const [warrantyWork, setWarrantyWork] = useState(workOrder.warranty_work);
  const [AFE, setAFE] = useState(workOrder.afe);

  const handleOpen = () => setShow(!show);

  const handleBillToCustomer = () => {
    if (!billToCustomer) {
      setBillToCustomer(true);
      setWarrantyWork(false);
      setAFE(false);
    } else {
      setBillToCustomer(false);
    }
  };

  const handleWarrantyWork = () => {
    if (!warrantyWork) {
      setWarrantyWork(true);
      setBillToCustomer(false);
      setAFE(false);
    } else {
      setWarrantyWork(false);
    }
  };

  const handleAFE = () => {
    if (!AFE) {
      setAFE(true);
      setBillToCustomer(false);
      setWarrantyWork(false);
    } else {
      setAFE(false);
    }
  };

  useEffect(() => {
    workOrder.billable = billToCustomer;
    workOrder.warranty_work = warrantyWork;
    workOrder.afe = AFE;
  }, [billToCustomer, warrantyWork, AFE]);

  return (
    <Card className={show ? 'bill-info-button-active' : 'bill-info-button'}>
      <Card className="bill-info-div" onClick={handleOpen}>
        <h6>Billing Information</h6>
      </Card>
      { show ? (
        <div className="bill-info-dropdown-div">
          <div className="bill-info-card">
            <Form.Check
              className="bill-info-checkbox"
              label="Billable to Customer"
              onClick={handleBillToCustomer}
              checked={billToCustomer}
            />
          </div>
          <div className="bill-info-card">
            <Form.Check
              className="bill-info-checkbox"
              label="Warranty Work"
              onClick={handleWarrantyWork}
              checked={warrantyWork}
            />
          </div>
          <div className="bill-info-card">
            <Form.Check
              className="bill-info-checkbox"
              label="AFE"
              onClick={handleAFE}
              checked={AFE}
            />
          </div>
        </div>
      ) : <div /> }
    </Card>
  );
};

export default BillInfo;
