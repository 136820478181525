import { useContext } from 'react';
import { WorkOrderContext } from '../context/WorkOrderContext';
import JSAForm from '../components/JSA/JSA';
import BasicInfo from '../components/WorkOrder/BasicInfo';
import Mileage from '../components/WorkOrder/Mileage';
import BillInfo from '../components/WorkOrder/BillInfo';
import H2STesting from '../components/WorkOrder/H2STesting';
import PMChecklist from '../components/WorkOrder/PMChecklist';
import EmissionsTesting from '../components/WorkOrder/EmissionsTesting';
import GasEngineChecklist from '../components/WorkOrder/GasEngineChecklist/GasEngineChecklist';
import UnitReadings from '../components/WorkOrder/UnitReadings';
import LaborCodes from '../components/WorkOrder/LaborCodes';

import './NewWorkOrder.scss';
import PartsUsed from '../components/WorkOrder/PartsUsed';

const NewWorkOrder = () => {
  const { workOrder } = useContext(WorkOrderContext);
  return (
    <div className="new-work">
      <div className="new-work-card">
        <div className="new-work-div">
          <h4>New Work Order</h4>
          <JSAForm />
        </div>
        <div>
          <p>Please fill out the following information</p>
        </div>
        <div className="new-work-card-div">
          <BasicInfo
            unitId={`${workOrder.unit_id}`}
          />
        </div>
        <div className="new-work-card-div">
          <BillInfo />
          <Mileage />
          <H2STesting />
        </div>
        <div className="new-work-card-div">
          <PMChecklist />
          <EmissionsTesting />
        </div>
        <div className="new-work-card-div">
          <GasEngineChecklist />
          <PartsUsed />
        </div>
        <div className="new-work-card-div">
          <UnitReadings />
        </div>
        <div className="new-work-card-div">
          <LaborCodes />
        </div>
      </div>
    </div>
  );
};

export default NewWorkOrder;
