import React, { ChangeEvent, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { GlobalContext } from '../../context/GlobalContext';
import { JSAContext } from '../../context/JSAContext';
import './JSADescriptionOfWork.scss';

const JSADescriptionOfWork = () => {
  const { jsa } = useContext(JSAContext);
  const { token } = useContext(GlobalContext);
  const [description, setDescription] = useState(jsa?.description_of_work);
  const handleInputChange = (input: ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(input.target.value as string);
  };
  const handleBlur = () => {
    jsa?.handleDescriptionOfWork(token, description as string);
  };

  return (
    <div>
      <div className="sub-section-header">
        <h6 className="title">Description of Work</h6>
        <h6 className="sub-title">Please provide a description of the work.</h6>
      </div>
      <Form.Text
        className="text-box"
        as="textarea"
        placeholder="Enter a Description"
        style={{ height: '180px', width: '506px' }}
        value={description}
        onBlur={handleBlur}
        onChange={handleInputChange}
      />
    </div>
  );
};

export default JSADescriptionOfWork;
