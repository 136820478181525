import React, { useContext, useState } from 'react';
import { Dropdown, Input, Button } from '@flogistix/flo-ui';
import { CloseButton } from 'react-bootstrap';
import { JSAContext } from '../../context/JSAContext';
import { GlobalContext } from '../../context/GlobalContext';
import './JSAContact.scss';

interface Contact {
  name: string
  phone_number: string
}

interface ContactInputProps {
  index: number;
  contacts: Contact[];
  onChange: (index: number, field: keyof Contact, value: string) => void;
  deleteContact: (index: number) => void;
  onBlur: (contacts: Contact[]) => void;
}

const ContactInput: React.FC<ContactInputProps> = ({
  onChange, index, contacts, deleteContact, onBlur
}) => (
  <div className="contact-block">
    <label className="label">Contact Name</label>
    <CloseButton className="delete-contact" onClick={() => deleteContact(index)} />
    <br />
    <Input
      className="contact-name-input"
      type="text"
      placeholder="Name"
      onChange={(e) => onChange(index, 'name', e.target.value)}
      onBlur={() => onBlur(contacts)}
    />
    <br />
    <label className="label">Phone Number</label>
    <br />
    <Input
      className="contact-phone-number-input"
      type="number"
      placeholder="+1 (100) 000-000"
      onChange={(e) => onChange(index, 'phone_number', e.target.value)}
      onBlur={() => onBlur(contacts)}
    />
  </div>
);

const JSAContact = () => {
  const { jsa } = useContext(JSAContext);
  const { token } = useContext(GlobalContext);

  const areaManagers = [['Benjamin Patrick', '12345678901'], ['Abraham Artus', '12345678900']];
  const areaManagerSearchItems = areaManagers.map((term) => ({ label: term[0], value: term[1] }));
  const [areaManagerTerm, setAreaManagerTerm] = useState<string>('');
  const [areaManager, setAreaManager] = useState(jsa?.contacts?.area_manager?.name);
  const [areaManagerNumber, setAreaManagerNumber] = useState(jsa?.contacts?.area_manager?.phone_number);
  const handleAreaManagerNumber = (event: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setAreaManagerNumber(event.target.value);
  };
  const handleAreaManager = (name: string, phone_number: string) => {
    setAreaManager(name);
    setAreaManagerNumber(phone_number);
    jsa?.handleAreaManager(token, name, phone_number);
  };

  const fireDepts = [['Station 1', '12345678921'], ['Station 2', '12345678920']];
  const localFireSearchItems = fireDepts.map((term) => ({ label: term[0], value: term[1] }));
  const [localFireTerm, setLocalFireTerm] = useState<string>('');
  const [localFire, setLocalFire] = useState(jsa?.contacts?.local_fire?.name);
  const [localFireNumber, setLocalFireNumber] = useState(jsa?.contacts?.local_fire?.phone_number);
  const handleLocalFireNumber = (event: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setLocalFireNumber(event.target.value);
  };
  const handleLocalFire = (name: string, phone_number: string) => {
    setLocalFire(name);
    setLocalFireNumber(phone_number);
    jsa?.handleLocalFire(token, name, phone_number);
  };

  const EHSManagers = [['Manager 1', '12345679921'], ['Manager 2', '12345679920']];
  const EHSManagerSearchItems = EHSManagers.map((term) => ({ label: term[0], value: term[1] }));
  const [EHSManagerTerm, setEHSManagerTerm] = useState<string>('');
  const [EHSManager, setEHSManager] = useState(jsa?.contacts?.EHS_manager?.name);
  const [EHSManagerNumber, setEHSManagerNumber] = useState(jsa?.contacts?.EHS_manager?.phone_number);
  const handleEHSManagerNumber = (event: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setEHSManagerNumber(event.target.value);
  };
  const handleEHSManager = (name: string, phone_number: string) => {
    setEHSManager(name);
    setEHSManagerNumber(phone_number);
    jsa?.handleEHSManager(token, name, phone_number);
  };

  const localHospitals = [['Hospital 1', '12345674443'], ['Hospital 2', '12345674444']];
  const localHospitalSearchItems = localHospitals.map((term) => ({ label: term[0], value: term[1] }));
  const [localHospitalTerm, setLocalHospitalTerm] = useState<string>('');
  const [localHospital, setLocalHospital] = useState(jsa?.contacts?.local_hospital?.name);
  const [localHospitalNumber, setLocalHospitalNumber] = useState(jsa?.contacts?.local_hospital?.phone_number);
  const handleLocalHospitalNumber = (event: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setLocalHospitalNumber(event.target.value);
  };
  const handleLocalHospital = (name: string, phone_number: string) => {
    setLocalHospital(name);
    setLocalHospitalNumber(phone_number);
    jsa?.handleLocalHospital(token, name, phone_number);
  };

  const localSheriffs = [['Sheriff 1', '12345674443'], ['Sheriff 2', '12345674444']];
  const localSheriffSearchItems = localSheriffs.map((term) => ({ label: term[0], value: term[1] }));
  const [localSheriffTerm, setLocalSheriffTerm] = useState<string>('');
  const [localSheriff, setLocalSheriff] = useState(jsa?.contacts?.local_sheriff?.name);
  const [localSheriffNumber, setLocalSheriffNumber] = useState(jsa?.contacts?.local_sheriff?.phone_number);
  const handleLocalSheriffNumber = (event: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
    setLocalSheriffNumber(event.target.value);
  };
  const handleLocalSheriff = (name: string, phone_number: string) => {
    setLocalSheriff(name);
    setLocalSheriffNumber(phone_number);
    jsa?.handleLocalSheriff(token, name, phone_number);
  };

  const [contacts, setContacts] = useState(jsa?.contacts?.other_contacts);
  const addContact = () => {
    const newContact: Contact = {
      name: '',
      phone_number: ''
    };
    const contactsCopy = [...contacts as Contact[]];
    contactsCopy.push(newContact);
    setContacts(contactsCopy);
    jsa?.handleOtherContact(token, contactsCopy);
  };
  const handleInputChange = (index: number, field: keyof Contact, value: string) => {
    const newContacts = [...contacts as Contact[]];
    if (field === 'name') {
      newContacts[index].name = value;
    } else if (field === 'phone_number') {
      newContacts[index].phone_number = value;
    }
    setContacts(newContacts);
  };
  const handleBlur = () => {
    jsa?.handleOtherContact(token, contacts as Contact[]);
  };
  const deleteContact = (index: number) => {
    const contactsCopy = [...contacts as Contact[]];
    contactsCopy.splice(index, 1);
    setContacts(contactsCopy);
    jsa?.handleOtherContact(token, contactsCopy);
  };

  return (
    <div>
      <div className="sub-section-header">
        <h6 className="title">Contact Information</h6>
        <h6 className="sub-title">Please enter contact information</h6>
      </div>
      <div className="container">
        <div className="contact-block">
          <label className="label">Area Manager</label>
          <br />
          <Dropdown
            className="contact-dropdown"
            placeholder="Select"
            options={
              areaManagerSearchItems.filter((item) => item.label.toLowerCase().includes(
                areaManagerTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setAreaManager(option.label); handleAreaManager(option.label, option.value as string);
            }}
            value={areaManager as string}
            searchValue={areaManagerTerm}
            onSearchChange={setAreaManagerTerm}
          />
          <label className="label">Phone Number</label>
          <br />
          <Input
            className="contact-phone-number-input"
            placeholder="+1 (000) 000-0000"
            onChange={handleAreaManagerNumber}
            value={areaManagerNumber}
            onBlur={() => handleAreaManager(areaManager as string, areaManagerNumber as string)}
          />
        </div>
        <div className="contact-block">
          <label className="label">Local Fire</label>
          <br />
          <Dropdown
            className="contact-dropdown"
            placeholder="Select"
            options={
              localFireSearchItems.filter((item) => item.label.toLowerCase().includes(
                localFireTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setLocalFire(option.label); handleLocalFire(option.label, option.value as string);
            }}
            value={localFire as string}
            searchValue={localFireTerm}
            onSearchChange={setLocalFireTerm}
          />
          <label className="label">Phone Number</label>
          <br />
          <Input
            className="contact-phone-number-input"
            placeholder="+1 (000) 000-0000"
            onChange={handleLocalFireNumber}
            value={localFireNumber}
            onBlur={() => handleLocalFire(localFire as string, localFireNumber as string)}
          />
        </div>
        <div className="contact-block">
          <label className="label">EHS Manager</label>
          <br />
          <Dropdown
            className="contact-dropdown"
            placeholder="Select"
            options={
              EHSManagerSearchItems.filter((item) => item.label.toLowerCase().includes(
                EHSManagerTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setEHSManager(option.label); handleEHSManager(option.label, option.value as string);
            }}
            value={EHSManager as string}
            searchValue={EHSManagerTerm}
            onSearchChange={setEHSManagerTerm}
          />
          <label className="label">Phone Number</label>
          <br />
          <Input
            className="contact-phone-number-input"
            placeholder="+1 (000) 000-0000"
            onChange={handleEHSManagerNumber}
            value={EHSManagerNumber}
            onBlur={() => handleEHSManager(EHSManager as string, EHSManagerNumber as string)}
          />
        </div>
        <div className="contact-block">
          <label className="label">Local Hospital</label>
          <br />
          <Dropdown
            className="contact-dropdown"
            placeholder="Select"
            options={
              localHospitalSearchItems.filter((item) => item.label.toLowerCase().includes(
                localHospitalTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setLocalHospital(option.label);
              handleLocalHospital(option.label, option.value as string);
            }}
            value={localHospital as string}
            searchValue={localHospitalTerm}
            onSearchChange={setLocalHospitalTerm}
          />
          <label className="label">Phone Number</label>
          <br />
          <Input
            className="contact-phone-number-input"
            placeholder="+1 (000) 000-0000"
            onChange={handleLocalHospitalNumber}
            value={localHospitalNumber}
            onBlur={() => handleLocalHospital(areaManager as string, areaManagerNumber as string)}
          />
        </div>
        <div className="contact-block">
          <label className="label">Local Sheriff</label>
          <br />
          <Dropdown
            className="contact-dropdown"
            placeholder="Select"
            options={
              localSheriffSearchItems.filter((item) => item.label.toLowerCase().includes(
                localSheriffTerm.toLowerCase()
              ))
            }
            onSelect={(option) => {
              setLocalSheriff(option.label); handleLocalSheriff(option.label, option.value as string);
            }}
            value={localSheriff as string}
            searchValue={localSheriffTerm}
            onSearchChange={setLocalSheriffTerm}
          />
          <label className="label">Phone Number</label>
          <br />
          <Input
            className="contact-phone-number-input"
            placeholder="+1 (000) 000-0000"
            onChange={handleLocalSheriffNumber}
            value={localSheriffNumber}
            onBlur={() => handleLocalSheriff(localSheriff as string, localSheriffNumber as string)}
          />
        </div>
        {
          contacts ? (
            contacts.map((contact, index) => (
              <ContactInput
                key={index as unknown as string}
                index={index}
                onChange={handleInputChange}
                contacts={contacts as Contact[]}
                deleteContact={deleteContact}
                onBlur={handleBlur}
              />
            ))
          ) : null
        }
      </div>
      <Button onClick={addContact} className="button-add-contact" variation="ghost">+ Other</Button>
    </div>
  );
};

export default JSAContact;
