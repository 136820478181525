import { ChangeEvent, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Dropdown } from '@flogistix/flo-ui';
import HazardsEnum from './enums/HazardEnums';
import { GlobalContext } from '../../context/GlobalContext';
import { JSAContext } from '../../context/JSAContext';
import './JSAHazardsAndControls.scss';

const JSAHazards = () => {
  const { token } = useContext(GlobalContext);
  const { jsa } = useContext(JSAContext);

  const [selectedHazardsJSA, setSelectedHazardsJSA] = useState(
    [
      jsa?.hazards?.body_position ? HazardsEnum.bodyPosition : '',
      jsa?.hazards?.pinch_crush_or_striking_hazard ? HazardsEnum.pinchCrushOrStrikingHazard : '',
      jsa?.hazards?.laceration_potential ? HazardsEnum.lacerationPotential : '',
      jsa?.hazards?.handling_lifting_hazard ? HazardsEnum.handlingLiftingHazard : '',
      jsa?.hazards?.hot_cold_equipment_surfaces ? HazardsEnum.hotColdEquipmentSurfaces : '',
      jsa?.hazards?.excavation_hazards ? HazardsEnum.excavationHazards : '',
      jsa?.hazards?.slick_surfaces ? HazardsEnum.slickSurfaces : '',
      jsa?.hazards?.crane_elevated_load_rigging ? HazardsEnum.craneElevatedLoadRigging : '',
      jsa?.hazards?.hazardous_chemical_exposure ? HazardsEnum.hazardousChemicalExposure : '',
      jsa?.hazards?.adjacent_work ? HazardsEnum.adjacentWork : '',
      jsa?.hazards?.ladders_or_fall_from_elevation ? HazardsEnum.laddersOrFallFromElevation : '',
      jsa?.hazards?.extreme_weather ? HazardsEnum.extremeWeather : '',
      jsa?.hazards?.heat_or_hypothermia ? HazardsEnum.heatOrHypothermia : '',
      jsa?.hazards?.h2s_or_so2_exposure ? HazardsEnum.h2sOrSo2Exposure : '',
      jsa?.hazards?.equipment_isolation_hazard ? HazardsEnum.equipmentIsolationHazard : '',
      jsa?.hazards?.fire_explosion_potential ? HazardsEnum.fireExplosionPotential : '',
      jsa?.hazards?.electrical_shock ? HazardsEnum.electricalShock : '',
      jsa?.hazards?.confined_spaces_entry ? HazardsEnum.confinedSpacesEntry : '',
      jsa?.hazards?.hot_liquids_gases ? HazardsEnum.hotLiquidsGases : ''
    ].filter((item) => item)
  );

  const addHazard = (hazard: string) => {
    setSelectedHazardsJSA((prevHazards) => [...prevHazards, hazard]);
  };
  const removeHazard = (hazard: string) => {
    setSelectedHazardsJSA((prevHazards) => prevHazards.filter((item) => item !== hazard));
  };

  const [hazardOneTerm, setHazardOneTerm] = useState<string>('');
  const [hazardOne, setHazardOne] = useState(jsa?.hazards?.plan?.first_hazard?.hazard as string);
  const [hazardOnePlan, setHazardOnePlan] = useState(jsa?.hazards?.plan?.first_hazard?.description);
  const handleHazardOneSelect = (event: string) => {
    setHazardOne(event);
    setHazardOnePlan('');
    jsa?.handleFirstHazardPlan(token, event, '');
  };
  const handleHazardOneChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHazardOnePlan(event.target.value);
  };
  const handleHazardOneBlur = () => {
    jsa?.handleFirstHazardPlan(token, hazardOne || '', hazardOnePlan || '');
  };

  const [hazardTwoTerm, setHazardTwoTerm] = useState<string>('');
  const [hazardTwo, setHazardTwo] = useState(jsa?.hazards?.plan?.second_hazard?.hazard as string);
  const [hazardTwoPlan, setHazardTwoPlan] = useState(jsa?.hazards?.plan?.second_hazard?.description);
  const handleHazardTwoSelect = (event: string) => {
    setHazardTwo(event);
    setHazardTwoPlan('');
    jsa?.handleSecondHazardPlan(token, event, '');
  };
  const handleHazardTwoChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHazardTwoPlan(event.target.value);
  };
  const handleHazardTwoBlur = () => {
    jsa?.handleSecondHazardPlan(token, hazardTwo || '', hazardTwoPlan || '');
  };

  const [hazardThreeTerm, setHazardThreeTerm] = useState<string>('');
  const [hazardThree, setHazardThree] = useState(jsa?.hazards?.plan?.third_hazard?.hazard as string);
  const [hazardThreePlan, setHazardThreePlan] = useState(jsa?.hazards?.plan?.third_hazard?.description);
  const handleHazardThreeSelect = (event: string) => {
    setHazardThree(event);
    setHazardThreePlan('');
    jsa?.handleThirdHazardPlan(token, event, '');
  };
  const handleHazardThreeChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setHazardThreePlan(event.target.value);
  };
  const handleHazardThreeBlur = () => {
    jsa?.handleThirdHazardPlan(token, hazardThree || '', hazardThreePlan || '');
  };

  const removedIfSelected = (hazard: string) => {
    if (!selectedHazardsJSA.includes(hazard)) return;
    if (hazardOne === hazard) {
      setHazardOne('');
      setHazardOnePlan('');
      jsa?.handleFirstHazardPlan(token, '', '');
    } else if (hazardTwo === hazard) {
      setHazardTwo('');
      setHazardTwoPlan('');
      jsa?.handleSecondHazardPlan(token, '', '');
    } else if (hazardThree === hazard) {
      setHazardThree('');
      setHazardThreePlan('');
      jsa?.handleThirdHazardPlan(token, '', '');
    }
  };

  const [bodyPosition, setBodyPosition] = useState(jsa?.hazards?.body_position || false);
  const handleBodyPosition = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.bodyPosition) : removeHazard(HazardsEnum.bodyPosition)))();
    if (newValue === false) removedIfSelected(HazardsEnum.bodyPosition);
    setBodyPosition(newValue);
    jsa?.handleHazardsBodyPosition(token, newValue);
  };

  const [pinchCrushOrStrikingHazard, setPinchCrushOrStrikingHazard] = useState(
    jsa?.hazards?.pinch_crush_or_striking_hazard
  );
  const handlePinchCrushOrStrikingHazard = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.pinchCrushOrStrikingHazard)
      : removeHazard(HazardsEnum.pinchCrushOrStrikingHazard)))();
    if (newValue === false) removedIfSelected(HazardsEnum.pinchCrushOrStrikingHazard);
    setPinchCrushOrStrikingHazard(newValue);
    jsa?.handleHazardsPinchCrushOrStrikingHazard(token, newValue);
  };

  const [lacerationPotential, setLacerationPotential] = useState(jsa?.hazards?.laceration_potential);
  const handleLacerationPotential = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.lacerationPotential) : removeHazard(HazardsEnum.lacerationPotential)))();
    if (newValue === false) removedIfSelected(HazardsEnum.lacerationPotential);
    setLacerationPotential(newValue);
    jsa?.handleHazardsLacerationPotential(token, newValue);
  };

  const [handlingLiftingHazard, setHandlingLiftingHazard] = useState(jsa?.hazards?.handling_lifting_hazard);
  const handleHandlingLiftingHazard = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.handlingLiftingHazard)
      : removeHazard(HazardsEnum.handlingLiftingHazard)))();
    if (newValue === false) removedIfSelected(HazardsEnum.handlingLiftingHazard);
    setHandlingLiftingHazard(newValue);
    jsa?.handleHazardsHandlingLifting(token, newValue);
  };

  const [laddersOrFallFromElevation, setLaddersOrFallFromElevation] = useState(
    jsa?.hazards?.ladders_or_fall_from_elevation
  );
  const handleLaddersOrFallFromElevation = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.laddersOrFallFromElevation)
      : removeHazard(HazardsEnum.laddersOrFallFromElevation)))();
    if (newValue === false) removedIfSelected(HazardsEnum.laddersOrFallFromElevation);
    setLaddersOrFallFromElevation(newValue);
    jsa?.handleHazardsLaddersOrFallFromElevation(token, newValue);
  };

  const [extremeWeather, setExtremeWeather] = useState(jsa?.hazards?.extreme_weather);
  const handleExtremeWeather = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.extremeWeather) : removeHazard(HazardsEnum.extremeWeather)))();
    if (newValue === false) removedIfSelected(HazardsEnum.extremeWeather);
    setExtremeWeather(newValue);
    jsa?.handleHazardsExtremeWeather(token, newValue);
  };

  const [heatOrHypothermia, setHeatOrHypothermia] = useState(jsa?.hazards?.heat_or_hypothermia);
  const handleHeatOrHypothermia = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.heatOrHypothermia) : removeHazard(HazardsEnum.heatOrHypothermia)))();
    if (newValue === false) removedIfSelected(HazardsEnum.heatOrHypothermia);
    setHeatOrHypothermia(newValue);
    jsa?.handleHazardsHeatOrHypothermia(token, newValue);
  };

  const [h2sOrSo2Exposure, setH2sOrSo2Exposure] = useState(jsa?.hazards?.h2s_or_so2_exposure);
  const handleH2sOrSo2Exposure = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.h2sOrSo2Exposure) : removeHazard(HazardsEnum.h2sOrSo2Exposure)))();
    if (newValue === false) removedIfSelected(HazardsEnum.h2sOrSo2Exposure);
    setH2sOrSo2Exposure(newValue);
    jsa?.handleHazardsH2SorSO2exposure(token, newValue);
  };

  const [hotColdEquipmentSurfaces, setHotColdEquipmentSurfaces] = useState(jsa?.hazards?.hot_cold_equipment_surfaces);
  const handleHotColdEquipmentSurfaces = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.hotColdEquipmentSurfaces)
      : removeHazard(HazardsEnum.hotColdEquipmentSurfaces)))();
    if (newValue === false) removedIfSelected(HazardsEnum.hotColdEquipmentSurfaces);
    setHotColdEquipmentSurfaces(newValue);
    jsa?.handleHazardsHotColdEquipmentSurfaces(token, newValue);
  };

  const [hazardousChemicalExposure, setHazardousChemicalExposure] = useState(jsa?.hazards?.hazardous_chemical_exposure);
  const handleHazardousChemicalExposure = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.hazardousChemicalExposure)
      : removeHazard(HazardsEnum.hazardousChemicalExposure)))();
    if (newValue === false) removedIfSelected(HazardsEnum.hazardousChemicalExposure);
    setHazardousChemicalExposure(newValue);
    jsa?.handleHazardsHazardousChemicalExposure(token, newValue);
  };

  const [hotLiquidsGases, setHotLiquidsGases] = useState(jsa?.hazards?.hot_liquids_gases);
  const handleHotLiquidsGases = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.hotLiquidsGases) : removeHazard(HazardsEnum.hotLiquidsGases)))();
    if (newValue === false) removedIfSelected(HazardsEnum.hotLiquidsGases);
    setHotLiquidsGases(newValue);
    jsa?.handleHazardsHotLiquidsGases(token, newValue);
  };

  const [excavationHazards, setExcavationHazards] = useState(jsa?.hazards?.excavation_hazards);
  const handleExcavationHazards = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.excavationHazards) : removeHazard(HazardsEnum.excavationHazards)))();
    if (newValue === false) removedIfSelected(HazardsEnum.excavationHazards);
    setExcavationHazards(newValue);
    jsa?.handleHazardsExcavationHazards(token, newValue);
  };

  const [slickSurfaces, setSlickSurfaces] = useState(jsa?.hazards?.slick_surfaces);
  const handleSlickSurfaces = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.slickSurfaces) : removeHazard(HazardsEnum.slickSurfaces)))();
    if (newValue === false) removedIfSelected(HazardsEnum.slickSurfaces);
    setSlickSurfaces(newValue);
    jsa?.handleHazardsSlickSurfaces(token, newValue);
  };

  const [craneElevatedLoadRigging, setCraneElevatedLoadRigging] = useState(jsa?.hazards?.crane_elevated_load_rigging);
  const handleCraneElevatedLoadRigging = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.craneElevatedLoadRigging)
      : removeHazard(HazardsEnum.craneElevatedLoadRigging)))();
    if (newValue === false) removedIfSelected(HazardsEnum.craneElevatedLoadRigging);
    setCraneElevatedLoadRigging(newValue);
    jsa?.handleHazardsCraneElevatedLoadRigging(token, newValue);
  };

  const [equipmentIsolationHazard, setEquipmentIsolationHazard] = useState(jsa?.hazards?.equipment_isolation_hazard);
  const handleEquipmentIsolationHazard = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.equipmentIsolationHazard)
      : removeHazard(HazardsEnum.equipmentIsolationHazard)))();
    if (newValue === false) removedIfSelected(HazardsEnum.equipmentIsolationHazard);
    setEquipmentIsolationHazard(newValue);
    jsa?.handleHazardsEquipmentIsolationHazard(token, newValue);
  };

  const [fireExplosionPotential, setFireExplosionPotential] = useState(jsa?.hazards?.fire_explosion_potential);
  const handleFireExplosionPotential = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.fireExplosionPotential)
      : removeHazard(HazardsEnum.fireExplosionPotential)))();
    if (newValue === false) removedIfSelected(HazardsEnum.fireExplosionPotential);
    setFireExplosionPotential(newValue);
    jsa?.handleHazardsFireExplosionPotential(token, newValue);
  };

  const [electricalShock, setElectricalShock] = useState(jsa?.hazards?.electrical_shock);
  const handleElectricalShock = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.electricalShock) : removeHazard(HazardsEnum.electricalShock)))();
    if (newValue === false) removedIfSelected(HazardsEnum.electricalShock);
    setElectricalShock(newValue);
    jsa?.handleHazardsElectricalShock(token, newValue);
  };

  const [confinedSpacesEntry, setConfinedSpacesEntry] = useState(jsa?.hazards?.confined_spaces_entry);
  const handleConfinedSpacesEntry = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.confinedSpacesEntry) : removeHazard(HazardsEnum.confinedSpacesEntry)))();
    if (newValue === false) removedIfSelected(HazardsEnum.confinedSpacesEntry);
    setConfinedSpacesEntry(newValue);
    jsa?.handleHazardsConfinedSpacesEntry(token, newValue);
  };

  const [adjacentWork, setAdjacentWork] = useState(jsa?.hazards?.adjacent_work);
  const handleAdjacentwork = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.checked;
    (() => (newValue ? addHazard(HazardsEnum.adjacentWork) : removeHazard(HazardsEnum.adjacentWork)))();
    if (newValue === false) removedIfSelected(HazardsEnum.adjacentWork);
    setAdjacentWork(newValue);
    jsa?.handleHazardsAdjacentWork(token, newValue);
  };

  const [otherDescription, setOtherDescription] = useState(jsa?.hazards?.other_description as string);
  const [otherDescriptionSelected, setOtherDescriptionSelected] = useState(otherDescription !== '');
  const handleDescriptionSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    (() => (newCheckedValue ? addHazard(HazardsEnum.other) : removeHazard(HazardsEnum.other)))();
    if (newCheckedValue === false) removedIfSelected(HazardsEnum.other);
    setOtherDescriptionSelected(newCheckedValue);
    if (!newCheckedValue) {
      jsa?.handleHazardsOtherDescription(token, '');
    } else if (otherDescription) {
      jsa?.handleHazardsOtherDescription(token, otherDescription);
    }
  };
  const handleOtherDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value !== '' && !otherDescriptionSelected) {
      setOtherDescriptionSelected(true);
      addHazard(HazardsEnum.other);
    }
    setOtherDescription(event.target.value);
  };
  const handleOtherDescriptionBlur = () => {
    if (otherDescriptionSelected) {
      jsa?.handleHazardsOtherDescription(token, otherDescription);
    }
  };

  return (
    <div>
      <h6 className="sub-title">How could I be injured? Select all that apply. (Minimum of 3)</h6>
      <div className="container">
        <div className="jsa-check-box-container">
          <Form.Check
            checked={bodyPosition as boolean}
            onChange={handleBodyPosition}
            className="jsa-check-box"
            label={HazardsEnum.bodyPosition}
            type="checkbox"
          />
          <Form.Check
            checked={pinchCrushOrStrikingHazard as boolean}
            onChange={handlePinchCrushOrStrikingHazard}
            className="jsa-check-box"
            label={HazardsEnum.pinchCrushOrStrikingHazard}
            type="checkbox"
          />
          <Form.Check
            checked={lacerationPotential as boolean}
            onChange={handleLacerationPotential}
            className="jsa-check-box"
            label={HazardsEnum.lacerationPotential}
            type="checkbox"
          />
          <Form.Check
            checked={handlingLiftingHazard as boolean}
            onChange={handleHandlingLiftingHazard}
            className="jsa-check-box"
            label={HazardsEnum.handlingLiftingHazard}
            type="checkbox"
          />
          <Form.Check
            checked={hotColdEquipmentSurfaces as boolean}
            onChange={handleHotColdEquipmentSurfaces}
            className="jsa-check-box"
            label={HazardsEnum.hotColdEquipmentSurfaces}
            type="checkbox"
          />
          <Form.Check
            checked={excavationHazards as boolean}
            onChange={handleExcavationHazards}
            className="jsa-check-box"
            label={HazardsEnum.excavationHazards}
            type="checkbox"
          />
          <Form.Check
            checked={slickSurfaces as boolean}
            onChange={handleSlickSurfaces}
            className="jsa-check-box"
            label={HazardsEnum.slickSurfaces}
            type="checkbox"
          />
          <Form.Check
            checked={craneElevatedLoadRigging as boolean}
            onChange={handleCraneElevatedLoadRigging}
            className="jsa-check-box"
            label={HazardsEnum.craneElevatedLoadRigging}
            type="checkbox"
          />
          <Form.Check
            checked={adjacentWork as boolean}
            onChange={handleAdjacentwork}
            className="jsa-check-box"
            label={HazardsEnum.adjacentWork}
            type="checkbox"
          />
          <Form.Check
            checked={hazardousChemicalExposure as boolean}
            onChange={handleHazardousChemicalExposure}
            className="jsa-check-box"
            label={HazardsEnum.hazardousChemicalExposure}
            type="checkbox"
          />
          <div>
            <Form.Check
              checked={otherDescriptionSelected as boolean}
              onChange={handleDescriptionSelected}
              className="jsa-check-box"
              label={HazardsEnum.other}
              type="checkbox"
            />
            <Form.Text
              className="other-text-box"
              as="textarea"
              placeholder="Enter a Description"
              value={otherDescription as string}
              onChange={handleOtherDescriptionChange}
              onBlur={handleOtherDescriptionBlur}
            />
          </div>
        </div>
        <div className="jsa-check-box-container">
          <Form.Check
            checked={laddersOrFallFromElevation as boolean}
            onChange={handleLaddersOrFallFromElevation}
            className="jsa-check-box"
            label={HazardsEnum.laddersOrFallFromElevation}
            type="checkbox"
          />
          <Form.Check
            checked={extremeWeather as boolean}
            onChange={handleExtremeWeather}
            className="jsa-check-box"
            label={HazardsEnum.extremeWeather}
            type="checkbox"
          />
          <Form.Check
            checked={heatOrHypothermia as boolean}
            onChange={handleHeatOrHypothermia}
            className="jsa-check-box"
            label={HazardsEnum.heatOrHypothermia}
            type="checkbox"
          />
          <Form.Check
            checked={h2sOrSo2Exposure as boolean}
            onChange={handleH2sOrSo2Exposure}
            className="jsa-check-box"
            label={HazardsEnum.h2sOrSo2Exposure}
            type="checkbox"
          />
          <Form.Check
            checked={equipmentIsolationHazard as boolean}
            onChange={handleEquipmentIsolationHazard}
            className="jsa-check-box"
            label={HazardsEnum.equipmentIsolationHazard}
            type="checkbox"
          />
          <Form.Check
            checked={fireExplosionPotential as boolean}
            onChange={handleFireExplosionPotential}
            className="jsa-check-box"
            label={HazardsEnum.fireExplosionPotential}
            type="checkbox"
          />
          <Form.Check
            checked={electricalShock as boolean}
            onChange={handleElectricalShock}
            className="jsa-check-box"
            label={HazardsEnum.electricalShock}
            type="checkbox"
          />
          <Form.Check
            checked={confinedSpacesEntry as boolean}
            onChange={handleConfinedSpacesEntry}
            className="jsa-check-box"
            label={HazardsEnum.confinedSpacesEntry}
            type="checkbox"
          />
          <Form.Check
            checked={hotLiquidsGases as boolean}
            onChange={handleHotLiquidsGases}
            className="jsa-check-box"
            label={HazardsEnum.hotLiquidsGases}
            type="checkbox"
          />
        </div>
      </div>
      <hr />
      <div>
        <div className="container">
          <div>
            <label className="label">Hazard 1</label>
            <Dropdown
              className="hazard-dropdown"
              placeholder="Select"
              options={selectedHazardsJSA.map((term) => (
                [hazardTwo, hazardThree].includes(term)
                  ? { label: '', value: '' }
                  : { label: term, value: term }
              )).filter((term) => term.label !== '')}
              onSelect={(option) => { handleHazardOneSelect(option.label); }}
              value={hazardOne}
              searchValue={hazardOneTerm}
              onSearchChange={setHazardOneTerm}
            />
            <Form.Text
              className="hazard-text-box"
              as="textarea"
              placeholder="Enter a Description"
              value={hazardOnePlan}
              onChange={handleHazardOneChange}
              onBlur={handleHazardOneBlur}
              disabled={!hazardOne}
            />
          </div>
          <div>
            <label className="label">Hazard 2</label>
            <Dropdown
              className="hazard-dropdown"
              placeholder="Select"
              options={selectedHazardsJSA.map((term) => (
                [hazardOne, hazardThree].includes(term)
                  ? { label: '', value: '' }
                  : { label: term, value: term }
              )).filter((term) => term.label !== '')}
              onSelect={(option) => handleHazardTwoSelect(option.label)}
              value={hazardTwo}
              searchValue={hazardTwoTerm}
              onSearchChange={setHazardTwoTerm}
            />
            <Form.Text
              className="hazard-text-box"
              as="textarea"
              placeholder="Enter a Description"
              value={hazardTwoPlan}
              onChange={handleHazardTwoChange}
              onBlur={handleHazardTwoBlur}
              disabled={!hazardTwo}
            />
          </div>
          <div>
            <label className="label">Hazard 3</label>
            <Dropdown
              className="hazard-dropdown"
              placeholder="Select"
              options={selectedHazardsJSA.map((term) => (
                [hazardOne, hazardTwo].includes(term)
                  ? { label: '', value: '' }
                  : { label: term, value: term }
              )).filter((term) => term.label !== '')}
              onSelect={(option) => handleHazardThreeSelect(option.label)}
              value={hazardThree}
              searchValue={hazardThreeTerm}
              onSearchChange={setHazardThreeTerm}
            />
            <Form.Text
              className="hazard-text-box"
              as="textarea"
              placeholder="Enter a Description"
              value={hazardThreePlan}
              onChange={handleHazardThreeChange}
              onBlur={handleHazardThreeBlur}
              disabled={!hazardThree}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default JSAHazards;
