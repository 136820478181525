import { SearchBar, Button } from '@flogistix/flo-ui';
import { useNavigate } from 'react-router-dom';
import './Home.scss';
import { useEffect, useContext, useState } from 'react';
import { GlobalContext } from '../context/GlobalContext';
import { WorkOrderContext } from '../context/WorkOrderContext';
import Loading from '../FullScreenLoader';

const Home = () => {
  const navigate = useNavigate();
  const { unitIds } = useContext(GlobalContext);
  const { workOrder } = useContext(WorkOrderContext);
  interface DropdownOption {
    label: string;
    value: number | string;
  }
  const [dropDownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selection, setSelection] = useState<string>('');
  const [isLoading, setLoading] = useState(false);

  const handleStart = () => {
    if (selection || unitIds.find((id) => id === searchTerm)) {
      workOrder.unit_id = selection?.toString() || searchTerm.toString();
      navigate('/workOrder/basicInfo');
    }
  };

  const handleSelect = (choice: DropdownOption) => {
    setSelection(choice.value as string);
  };

  const mapIdsToDropdown = (ids: string[]) => {
    const dropdownIds = ids.map((num) => ({
      label: `${num}`,
      value: num
    })) as DropdownOption[];
    return dropdownIds;
  };

  useEffect(() => {
    if (unitIds) {
      const options: string[] = unitIds.filter((id) => id === searchTerm);
      setDropdownOptions(mapIdsToDropdown(options));
    }
  }, [searchTerm, unitIds]);

  useEffect(() => {
    if (unitIds) setLoading(true);
  }, [unitIds]);

  return isLoading ? (
    <div className="home">
      <div className="home-card">
        <div>
          <h2 className="home-header">
            Start New Work Order
          </h2>
        </div>
        <h5 className="home-label">Enter Unit Id:</h5>
        <div className="home-div">
          <SearchBar
            dropShadow
            placeholder="Unit Id"
            variation="search"
            size="small"
            dropdownOptions={dropDownOptions}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setSelection('');
            }}
            onSelect={handleSelect}
          />
          <label className="home-label" />
          <Button className="home-button" onClick={handleStart} size="medium">Start</Button>
        </div>
      </div>
    </div>
  ) : <Loading />;
};

export default Home;
