import { PiSquaresFour } from 'react-icons/pi';
import { MdOutlineWorkOutline } from 'react-icons/md';
import './NavBar.scss';

interface NavItem {
  key: string;
  title: string;
  path: string;
  icon: JSX.Element;
}

export const NavItems: NavItem[] = [
  {
    key: 'home',
    title: 'Home',
    path: '/home',
    icon: <PiSquaresFour className="ant-menu-item-icon" />
  },
  {
    key: 'workOrderHistory',
    title: 'Work Order History',
    path: '/workOrdersHistory',
    icon: <MdOutlineWorkOutline className="ant-menu-item-icon" />
  }
];

export default NavItems;
