import { Input } from '@flogistix/flo-ui';
import './GasEngineChecklist.scss';

const GasEngineReadings = () => (
  <div className="ge-input-div">
    <div className="">
      <label className="ge-label">Engine Oil Pressure</label>
      <br />
      <Input type="number" className="ge-input" fixedWidth="110px" />
    </div>
    <div>
      <label className="ge-label">Engine J/Water Temp</label>
      <br />
      <Input type="number" className="ge-input" fixedWidth="110px" />
    </div>
    <div>
      <label className="ge-label">Engine Manifold Vac/Press</label>
      <br />
      <Input type="number" className="ge-input" fixedWidth="110px" />
    </div>
  </div>
);

export default GasEngineReadings;
