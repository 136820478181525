import { v4 as uuidv4 } from 'uuid';
import { LOCAL_API_URL, WORK_ORDER_DEV_API_URL } from '../shared/url';
import { Transaction } from '../dexie/db';
import { createTransaction } from '../dexie/operations';

interface Coordinate {
  longitude: number;
  latitude: number;
}

export class WorkOrder {
  id?: string;

  parentId?: string;

  version?: number;

  createdAt?: Date;

  createdBy?: string;

  updatedAt?: Date;

  updatedBy?: string;

  deleted?: boolean;

  lastAct?: string;

  jsa_id?: string;

  mechanic_id?: number;

  application_type?: string;

  unit_id?: string;

  computer_id?: number;

  truck_id?: number;

  customer_contact_id?: number;

  lease_id?: number;

  work_order_type?: string;

  owner?: string;

  start_time?: Date;

  end_time?: Date;

  mileage_start?: number;

  mileage_end?: number;

  start_gps?: Coordinate;

  end_gps?: Coordinate;

  county?: string;

  state?: string;

  billable?: boolean;

  warranty_work?: boolean;

  afe?: boolean;

  approval_code?: string;

  billable_hours?: number;

  billable_miles?: number;

  part_ids?: string[];

  created?: boolean;

  constructor() {
    if (!this.id) {
      this.id = uuidv4();
      this.billable = false;
      this.warranty_work = false;
      this.afe = false;
      this.part_ids = [];
    }
  }

  async createWorkOrder(token: string) {
    this.created = true;
    this.createdAt = new Date();
    if (this.id) {
      const transaction = {
        queueId: `${this.id}`,
        type: 'workOrder',
        request: {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          payload: this as object,
          endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders`
        }
      } as Transaction;
      await createTransaction(transaction, this);
    }
  }

  async handleApplicationType(token: string, value: string) {
    this.application_type = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { application_type: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/applicationType`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleUnitId(token: string, value: string) {
    this.unit_id = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { unit_id: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/unitId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleComputerId(token: string, value: number) {
    this.computer_id = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { computer_id: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/computerId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleTruckId(token: string, value: number) {
    this.truck_id = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { truck_id: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/truckId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleCustomerContactId(token: string, value: number) {
    this.customer_contact_id = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { customer_contact_id: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/customerContactId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleLeaseId(token: string, value: number) {
    this.lease_id = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { lease_id: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/leaseId`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWorkOrderType(token: string, value: string) {
    this.work_order_type = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { work_order_type: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/workOrderType`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleOwner(token: string, value: string) {
    this.owner = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { owner: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/owner`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleStartTime(token: string, value: Date) {
    this.start_time = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { start_time: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/startTime`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleEndTime(token: string, value: Date) {
    this.end_time = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { end_time: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/endTime`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleMileageStart(token: string, value: number) {
    this.mileage_start = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { mileage_start: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/mileageStart`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleMileageEnd(token: string, value: number) {
    this.mileage_end = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { mileage_end: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/mileageEnd`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleStartGPS(token: string, value: Coordinate) {
    this.start_gps = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { start_gps: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/startGPS`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleEndGPS(token: string, value: Coordinate) {
    this.end_gps = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { end_gps: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/endGPS`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleCounty(token: string, value: string) {
    this.county = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { county: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/county`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleState(token: string, value: string) {
    this.state = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { state: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/state`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleBillable(token: string, value: boolean) {
    this.billable = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { billable: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/billable`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleWarrantyWork(token: string, value: boolean) {
    this.warranty_work = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { warranty_work: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/warrantyWork`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleAFE(token: string, value: boolean) {
    this.afe = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { afe: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/AFE`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleApprovalCode(token: string, value: string) {
    this.approval_code = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { approval_code: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/approvalCode`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleBillableHours(token: string, value: number) {
    this.billable_hours = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { billable_hours: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/billableHours`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handleBillableMiles(token: string, value: number) {
    this.billable_miles = value;
    if (!this.created) return;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { billable_miles: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/billableMiles`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }

  async handlePartIds(token: string, value: string[]) {
    if (!this.created) return;
    this.part_ids = value;
    const transaction = {
      queueId: `${this.id}`,
      type: 'workOrder',
      request: {
        method: 'PATCH',
        headers: {
          'if-match': '',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        payload: { part_ids: `${value}` },
        endpoint: `${WORK_ORDER_DEV_API_URL}/workOrders/${this.id}/partIds`,
        version: `${this.version}`
      }
    } as Transaction;
    await createTransaction(transaction, this);
  }
}

export default WorkOrder;
