import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import '../../pages/NewWorkOrder.scss';

interface BasicInfoProps {
  unitId: string;
}

const BasicInfo = ({ unitId }: BasicInfoProps) => {
  useEffect(() => {
    console.log(unitId);
  }, [unitId]);

  const handleClick = () => {
    console.log('hello');
  };

  return (
    <Card onClick={handleClick} className="full-button">
      <h6>Basic Info</h6>
    </Card>
  );
};

export default BasicInfo;
