type Message = {
  type: string
}

const sendMessageToSW = (message: Message) => {
  if (navigator.serviceWorker && navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage(message);
  } else {
    console.error('No active service worker found to send a message.');
  }
};

export default sendMessageToSW;
