import { useEffect, useState } from 'react';
import { Menu } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';
import './NavBar.scss';
import { NavItem } from 'react-bootstrap';

interface NavItem {
  key: string;
  title: string;
  path: string;
  icon: JSX.Element;
}

interface NavProps {
  navItems: NavItem[];
}

const buildItemLinks = (navItems: NavItem[]) => navItems.map((linkItem: NavItem) => ({
  ...linkItem,
  label: linkItem.title,
  icon: (
    <NavLink
      to={linkItem.path}
      key={linkItem.key}
    >
      {linkItem.icon}
    </NavLink>
  )
}));

const SideNav: React.FC<NavProps> = ({ navItems }: NavProps) => {
  const { pathname } = useLocation();
  const [activeLink, setActiveLink] = useState('');

  useEffect(() => {
    let activeKey = navItems.find((item) => item.path === pathname)?.key;
    if (pathname.includes('/workOrder/')) activeKey = 'home';
    if (activeKey) setActiveLink(activeKey);
  }, [pathname]);

  return activeLink ? (
    <Menu
      mode="inline"
      inlineCollapsed
      items={buildItemLinks(navItems)}
      defaultSelectedKeys={[activeLink]}
    />
  ) : <div />;
};

export default SideNav;
