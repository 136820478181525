import { Card, Form } from 'react-bootstrap';
import { Input } from '@flogistix/flo-ui';
import { useState } from 'react';
import './PMChecklist.scss';

const PMChecklist = () => {
  const [show, setShow] = useState(false);
  const handleOpen = () => setShow(!show);
  return (
    <Card className={show ? 'pm-button-active' : 'pm-button'}>
      <Card className="pm-card" onClick={handleOpen}>
        <h6>Preventative Maintenance Checklist</h6>
      </Card>
      {show ? (
        <div className="pm-dropdown-div">
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Visual and Auditory Inspection" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Check Differential Pressure" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Function Test Capacity Control" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Grease Bearings" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Check All Shutdowns" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Test Scrubber Dumps" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Check Auto Blow Down" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Lock Out Tag Out (LOTO)" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Change Filters" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Check Viscosity" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Function Test Recycle" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Visual Check of Oil Level While Running" />
            </div>
          </div>
          <div className="pm-div">
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Validate Cooling System/ Temp Control" />
            </div>
            <div className="pm-space-div">
              <Form.Check className="checkbox" label="Put in &quot;Down for Maintenance&quot; Mode" />
            </div>
          </div>
          <div className="pm-bottom-div">
            <label className="pm-label">Viscosity</label>
            <br />
            <Input className="pm-input" fixedWidth="40%" />
            <div className="pm-div">
              <Form.Check className="checkbox" label="Change Oil" />
            </div>
          </div>
        </div>
      ) : <div /> }
    </Card>
  );
};

export default PMChecklist;
