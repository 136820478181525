import React, { ChangeEvent, useContext, useState } from 'react';
import { Form } from 'react-bootstrap';
import ControlsEnum from './enums/ControlEnums';
import { JSAContext } from '../../context/JSAContext';
import { GlobalContext } from '../../context/GlobalContext';
import './JSAHazardsAndControls.scss';

const JSAControlsAndPractices = () => {
  const { token } = useContext(GlobalContext);
  const { jsa } = useContext(JSAContext);

  const [confinedSpacesEntry, setConfinedSpacesEntry] = useState(
    jsa?.controls?.confined_space_entry_procedure || false
  );
  const handleConfinedSpacesEntry = (event: ChangeEvent<HTMLInputElement>) => {
    setConfinedSpacesEntry(event.target.checked);
    jsa?.handleControlsConfinedSpaceEntryProcedure(token, event.target.checked);
  };

  const [useOfBarricades, setUseOfBarricades] = useState(jsa?.controls?.use_of_barricades);
  const handleUseOfBarricades = (event: ChangeEvent<HTMLInputElement>) => {
    setUseOfBarricades(event.target.checked);
    jsa?.handleControlsUseOfBarricades(token, event.target.checked);
  };

  const [spillKitOrContainment, setSpillKitOrContainment] = useState(jsa?.controls?.spill_kit_or_containment);
  const handleSpillKitOrContainment = (event: ChangeEvent<HTMLInputElement>) => {
    setSpillKitOrContainment(event.target.checked);
    jsa?.handleControlsSpillKitOrContainment(token, event.target.checked);
  };

  const [reviewEmergencyPlan, setReviewEmergencyPlan] = useState(jsa?.controls?.review_emergency_plan);
  const handleReviewEmergencyPlan = (event: ChangeEvent<HTMLInputElement>) => {
    setReviewEmergencyPlan(event.target.checked);
    jsa?.handleControlsReviewEmergencyPlan(token, event.target.checked);
  };

  const [drinkFluids, setDrinkFluids] = useState(jsa?.controls?.drink_fluids);
  const handleDrinkFluids = (event: ChangeEvent<HTMLInputElement>) => {
    setDrinkFluids(event.target.checked);
    jsa?.handleControlsDrinkFluids(token, event.target.checked);
  };

  const [electricianNeeded, setElectricianNeeded] = useState(jsa?.controls?.electrician_needed);
  const handleElectricianNeeded = (event: ChangeEvent<HTMLInputElement>) => {
    setElectricianNeeded(event.target.checked);
    jsa?.handlecontrolsElectricianNeeded(token, event.target.checked);
  };

  const [appropriatePPE, setAppropriatePPE] = useState(jsa?.controls?.use_appropriate_ppe);
  const handleAppriopriatePPE = (event: ChangeEvent<HTMLInputElement>) => {
    setAppropriatePPE(event.target.checked);
    jsa?.handleControlsUseAppropriatePPE(token, event.target.checked);
  };

  const [lockOutTagOut, setLockOutTagOut] = useState(jsa?.controls?.lock_out_tag_out);
  const handleLockOutTagOut = (event: ChangeEvent<HTMLInputElement>) => {
    setLockOutTagOut(event.target.checked);
    jsa?.handleControlsLockOutTagOut(token, event.target.checked);
  };

  const [fallProtectionOrLadder, setFallProtectionOrLadder] = useState(jsa?.controls?.fall_protection_or_ladder);
  const handleFallProtectionOrLadder = (event: ChangeEvent<HTMLInputElement>) => {
    setFallProtectionOrLadder(event.target.checked);
    jsa?.handleControlsFallProtectionOrLadder(token, event.target.checked);
  };

  const [depressurize, setDepressurize] = useState(jsa?.controls?.depressurize);
  const handleDepressurize = (event: ChangeEvent<HTMLInputElement>) => {
    setDepressurize(event.target.checked);
    jsa?.handleControlsDepressurize(token, event.target.checked);
  };

  const [fourGasMonitorUse, setFourGasMonitorUse] = useState(jsa?.controls?.four_gas_monitor_use);
  const handleFourGasMonitorUse = (event: ChangeEvent<HTMLInputElement>) => {
    setFourGasMonitorUse(event.target.checked);
    jsa?.handleControlsFourGasMonitorUse(token, event.target.checked);
  };

  const [equipmentCoolDown, setEquipmentCoolDown] = useState(jsa?.controls?.equipment_cool_down);
  const handleEquipmentCoolDown = (event: ChangeEvent<HTMLInputElement>) => {
    setEquipmentCoolDown(event.target.checked);
    jsa?.handleControlsEquipmentCoolDown(token, event.target.checked);
  };

  const [fireExtinguisher, setFireExtinguisher] = useState(jsa?.controls?.fire_extinguisher);
  const handleFireExtinguisher = (event: ChangeEvent<HTMLInputElement>) => {
    setFireExtinguisher(event.target.checked);
    jsa?.handleControlsFireExtinguisher(token, event.target.checked);
  };

  const [otherDescription, setOtherDescription] = useState(jsa?.hazards?.other_description as string);
  const [otherDescriptionSelected, setOtherDescriptionSelected] = useState(otherDescription !== '');
  const handleDescriptionSelected = (event: ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = event.target.checked;
    setOtherDescriptionSelected(newCheckedValue);
    if (!newCheckedValue) {
      jsa?.handleHazardsOtherDescription(token, '');
    } else if (otherDescription) {
      jsa?.handleHazardsOtherDescription(token, otherDescription);
    }
  };
  const handleOtherDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (event.target.value !== '' && !otherDescriptionSelected) {
      setOtherDescriptionSelected(true);
    }
    setOtherDescription(event.target.value);
  };
  const handleOtherDescriptionBlur = () => {
    if (otherDescriptionSelected) {
      jsa?.handleHazardsOtherDescription(token, otherDescription);
    }
  };

  return (
    <div>
      <h1 className="sub-title">Controls and safe work practices to ensure we work safe. Check all that apply.</h1>
      <div className="container">
        <div className="jsa-check-box-container">
          <Form.Check
            checked={confinedSpacesEntry}
            onChange={handleConfinedSpacesEntry}
            className="jsa-check-box"
            label={ControlsEnum.confinedSpaceEntryProcedure}
            type="checkbox"
          />
          <Form.Check
            checked={useOfBarricades}
            onChange={handleUseOfBarricades}
            className="jsa-check-box"
            label={ControlsEnum.useOfBarricades}
            type="checkbox"
          />
          <Form.Check
            checked={spillKitOrContainment}
            onChange={handleSpillKitOrContainment}
            className="jsa-check-box"
            label={ControlsEnum.spillKitOrContainment}
            type="checkbox"
          />
          <Form.Check
            checked={reviewEmergencyPlan}
            onChange={handleReviewEmergencyPlan}
            className="jsa-check-box"
            label={ControlsEnum.reviewEmergencyPlan}
            type="checkbox"
          />
          <Form.Check
            checked={drinkFluids}
            onChange={handleDrinkFluids}
            className="jsa-check-box"
            label={ControlsEnum.drinkFluids}
            type="checkbox"
          />
          <Form.Check
            checked={electricianNeeded}
            onChange={handleElectricianNeeded}
            className="jsa-check-box"
            label={ControlsEnum.electricianNeeded}
            type="checkbox"
          />
          <Form.Check
            checked={appropriatePPE}
            onChange={handleAppriopriatePPE}
            className="jsa-check-box"
            label={ControlsEnum.useAppropriatePPE}
            type="checkbox"
          />
          <div>
            <Form.Check
              checked={otherDescriptionSelected as boolean}
              onChange={handleDescriptionSelected}
              className="jsa-check-box"
              label={ControlsEnum.otherDescription}
              type="checkbox"
            />
            <Form.Text
              className="other-text-box"
              as="textarea"
              placeholder="Enter a Description"
              value={otherDescription as string}
              onChange={handleOtherDescriptionChange}
              onBlur={handleOtherDescriptionBlur}
            />
          </div>
        </div>
        <div className="jsa-check-box-container">
          <Form.Check
            checked={lockOutTagOut}
            onChange={handleLockOutTagOut}
            className="jsa-check-box"
            label={ControlsEnum.lockOutTagOut}
            type="checkbox"
          />
          <Form.Check
            checked={fallProtectionOrLadder}
            onChange={handleFallProtectionOrLadder}
            className="jsa-check-box"
            label={ControlsEnum.fallProtectionOrLadder}
            type="checkbox"
          />
          <Form.Check
            checked={depressurize}
            onChange={handleDepressurize}
            className="jsa-check-box"
            label={ControlsEnum.depressurize}
            type="checkbox"
          />
          <Form.Check
            checked={fourGasMonitorUse}
            onChange={handleFourGasMonitorUse}
            className="jsa-check-box"
            label={ControlsEnum.fourGasMonitorUse}
            type="checkbox"
          />
          <Form.Check
            checked={equipmentCoolDown}
            onChange={handleEquipmentCoolDown}
            className="jsa-check-box"
            label={ControlsEnum.equipmentCoolDown}
            type="checkbox"
          />
          <Form.Check
            checked={fireExtinguisher}
            onChange={handleFireExtinguisher}
            className="jsa-check-box"
            label={ControlsEnum.fireExtinguisher}
            type="checkbox"
          />
        </div>
      </div>
    </div>
  );
};

export default JSAControlsAndPractices;
